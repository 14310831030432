/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MobileAppPaymentSettingsFee } from './mobileAppPaymentSettingsFee';

export interface MobileAppPaymentSystemSettings {
  /**
   * Submerchant API key (optional, for eway only)
   */
  api_key?: string;
  application_fee?: MobileAppPaymentSettingsFee;
  customer_application_fee?: MobileAppPaymentSettingsFee;
  /**
   * Submerchant API key (optional, for eway only)
   */
  password?: string;
  /**
   * Settings scope
   */
  scope?: MobileAppPaymentSystemSettings.ScopeEnum;
  /**
   * Submerchant payment by card token service Id (optional, for 4bill only)
   */
  service_id_payment_by_token?: number;
  /**
   * Submerchant card tokenization (by success payment) service Id (optional, for 4bill only)
   */
  service_id_tokenize_by_payment?: number;
  /**
   * Submerchant Id
   */
  smch_id?: string;
  /**
   * Amount from which 3DS verification needed (optional, for ipay only)
   */
  verification_limit?: number;
  /**
   * Submerchant wallet Id (optional, for 4bill only)
   */
  wallet_id?: number;
}
export namespace MobileAppPaymentSystemSettings {
  export type ScopeEnum = 'platform' | 'business' | 'loyalty' | 'pos';
  export const ScopeEnum = {
    Platform: 'platform' as ScopeEnum,
    Business: 'business' as ScopeEnum,
    Loyalty: 'loyalty' as ScopeEnum,
    Pos: 'pos' as ScopeEnum
  };
}
