import {
  Injectable,
  signal,
  computed,
  WritableSignal,
  Inject
} from '@angular/core';
import { Observable } from 'rxjs';
import { AUTH_OPTIONS, AuthOptions } from '../auth.module';
import { DefaultService } from '@incust/api-term';

@Injectable({
  providedIn: 'root'
})
export class AuthHolderService {
  private tokenSignal: WritableSignal<string | null> = signal<string | null>(
    this.getTokenFromLocalStorage()
  );

  // Computed property to check if the user is authenticated
  isAuthenticated = computed(() => this.tokenSignal() !== null);

  constructor(
    private auth: DefaultService,
    @Inject(AUTH_OPTIONS) private options: AuthOptions
  ) {}

  /**
   * Retrieves the token from local storage
   */
  private getTokenFromLocalStorage(): string | null {
    return localStorage.getItem('auth_token');
  }

  /**
   * Saves the token to local storage and updates the signal
   * @param token The token to store
   */
  private setTokenToLocalStorage(token: string): void {
    localStorage.setItem('auth_token', token);
    this.tokenSignal.set(token);
  }

  /**
   * Removes the token from local storage and updates the signal
   */
  logout(): void {
    localStorage.removeItem('auth_token');
    this.tokenSignal.set(null);
  }

  /**
   * Retrieves the current token
   * @returns The current token or null
   */
  getToken(): string | null {
    return this.tokenSignal();
  }

  /**
   * Retrieves the current options
   * @returns The current options or null
   */
  getOptions(): AuthOptions | null {
    return this.options;
  }

  /**
   * Logs in the user and stores the token upon successful authentication
   * @param credentials User credentials (login and password)
   * @returns Observable for login operation
   */
  login(credentials: { login: string; password: string }) {
    return new Observable((observer) => {
      this.auth
        .incustControllersTermApiToken({
          login: credentials.login,
          password: credentials.password
        })
        .subscribe({
          next: (response) => {
            if (!response?.token) {
              observer.error('No token provided');
              return;
            }
            this.setTokenToLocalStorage(response.token);
            observer.next(response);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          }
        });
    });
  }
}
