/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { IncustControllersTermApiOnlineStoreChangeOrderStatusRequest } from '../model/incustControllersTermApiOnlineStoreChangeOrderStatusRequest';
// @ts-ignore
import { MessageResponse } from '../model/messageResponse';
// @ts-ignore
import { Order } from '../model/order';
// @ts-ignore
import { OrderListResult } from '../model/orderListResult';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class OnlineStoreService {
  protected basePath = '/v1/term';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substring(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Change order status and return order object
   * @param orderId Order Id
   * @param data Action info
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiOnlineStoreChangeOrderStatus(
    orderId: string,
    data: IncustControllersTermApiOnlineStoreChangeOrderStatusRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Order>;
  public incustControllersTermApiOnlineStoreChangeOrderStatus(
    orderId: string,
    data: IncustControllersTermApiOnlineStoreChangeOrderStatusRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Order>>;
  public incustControllersTermApiOnlineStoreChangeOrderStatus(
    orderId: string,
    data: IncustControllersTermApiOnlineStoreChangeOrderStatusRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Order>>;
  public incustControllersTermApiOnlineStoreChangeOrderStatus(
    orderId: string,
    data: IncustControllersTermApiOnlineStoreChangeOrderStatusRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling incustControllersTermApiOnlineStoreChangeOrderStatus.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiOnlineStoreChangeOrderStatus.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/online_store/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}`;
    return this.httpClient.request<Order>(
      'patch',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return user orders list
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param userIdType Type of user identifier
   * @param orderType Order type filtering if needed
   * @param summaryOnly Return only summary information
   * @param page List page number
   * @param count Transactions count in page
   * @param dateFrom Date and time filter from in RFC3339
   * @param dateTo Date and time to from in RFC3339
   * @param number Order number if need
   * @param notPaidOnly Return only not paid orders
   * @param notInRouteOnly Return only orders that not in route
   * @param notCompletedOnly Return only orders that not completed (doesn\&#39;t have the status \&#39;delivered\&#39; or \&#39;canceled\&#39;)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiOnlineStoreCustomerOrders(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<OrderListResult>;
  public incustControllersTermApiOnlineStoreCustomerOrders(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<OrderListResult>>;
  public incustControllersTermApiOnlineStoreCustomerOrders(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<OrderListResult>>;
  public incustControllersTermApiOnlineStoreCustomerOrders(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiOnlineStoreCustomerOrders.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }
    if (orderType !== undefined && orderType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>orderType,
        'order_type'
      );
    }
    if (summaryOnly !== undefined && summaryOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>summaryOnly,
        'summary_only'
      );
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page'
      );
    }
    if (count !== undefined && count !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>count,
        'count'
      );
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>dateFrom,
        'date_from'
      );
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>dateTo,
        'date_to'
      );
    }
    if (number !== undefined && number !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>number,
        'number'
      );
    }
    if (notPaidOnly !== undefined && notPaidOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notPaidOnly,
        'not_paid_only'
      );
    }
    if (notInRouteOnly !== undefined && notInRouteOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notInRouteOnly,
        'not_in_route_only'
      );
    }
    if (notCompletedOnly !== undefined && notCompletedOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notCompletedOnly,
        'not_completed_only'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/online_store/user/orders`;
    return this.httpClient.request<OrderListResult>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return user orders list
   * @param userIdValue User phone number
   * @param userIdType Type of user identity
   * @param orderType Order type filtering if needed
   * @param summaryOnly Return only summary information
   * @param page List page number
   * @param count Transactions count in page
   * @param dateFrom Date and time filter from in RFC3339
   * @param dateTo Date and time to from in RFC3339
   * @param number Order number if need
   * @param notPaidOnly Return only not paid orders
   * @param notInRouteOnly Return only orders that not in route
   * @param notCompletedOnly Return only orders that not completed (doesn\&#39;t have the status \&#39;delivered\&#39; or \&#39;canceled\&#39;)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiOnlineStoreCustomerOrdersEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<OrderListResult>;
  public incustControllersTermApiOnlineStoreCustomerOrdersEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<OrderListResult>>;
  public incustControllersTermApiOnlineStoreCustomerOrdersEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<OrderListResult>>;
  public incustControllersTermApiOnlineStoreCustomerOrdersEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiOnlineStoreCustomerOrdersEx.'
      );
    }
    if (userIdType === null || userIdType === undefined) {
      throw new Error(
        'Required parameter userIdType was null or undefined when calling incustControllersTermApiOnlineStoreCustomerOrdersEx.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (orderType !== undefined && orderType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>orderType,
        'order_type'
      );
    }
    if (summaryOnly !== undefined && summaryOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>summaryOnly,
        'summary_only'
      );
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page'
      );
    }
    if (count !== undefined && count !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>count,
        'count'
      );
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>dateFrom,
        'date_from'
      );
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>dateTo,
        'date_to'
      );
    }
    if (number !== undefined && number !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>number,
        'number'
      );
    }
    if (notPaidOnly !== undefined && notPaidOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notPaidOnly,
        'not_paid_only'
      );
    }
    if (notInRouteOnly !== undefined && notInRouteOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notInRouteOnly,
        'not_in_route_only'
      );
    }
    if (notCompletedOnly !== undefined && notCompletedOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notCompletedOnly,
        'not_completed_only'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/online_store/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/${this.configuration.encodeParam({
      name: 'userIdType',
      value: userIdType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'phone' | 'qr' | 'email' | 'card' | 'temporary-card' | 'id' | 'external-id' | 'social-network' | 'ssn' | 'itin' | 'itn'",
      dataFormat: undefined
    })}/orders`;
    return this.httpClient.request<OrderListResult>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return user orders list
   * @param userIdValue User phone number
   * @param orderType Order type filtering if needed
   * @param summaryOnly Return only summary information
   * @param page List page number
   * @param count Transactions count in page
   * @param dateFrom Date and time filter from in RFC3339
   * @param dateTo Date and time to from in RFC3339
   * @param number Order number if need
   * @param notPaidOnly Return only not paid orders
   * @param notInRouteOnly Return only orders that not in route
   * @param notCompletedOnly Return only orders that not completed (doesn\&#39;t have the status \&#39;delivered\&#39; or \&#39;canceled\&#39;)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiOnlineStoreCustomerOrdersSimple(
    userIdValue: string,
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<OrderListResult>;
  public incustControllersTermApiOnlineStoreCustomerOrdersSimple(
    userIdValue: string,
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<OrderListResult>>;
  public incustControllersTermApiOnlineStoreCustomerOrdersSimple(
    userIdValue: string,
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<OrderListResult>>;
  public incustControllersTermApiOnlineStoreCustomerOrdersSimple(
    userIdValue: string,
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiOnlineStoreCustomerOrdersSimple.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (orderType !== undefined && orderType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>orderType,
        'order_type'
      );
    }
    if (summaryOnly !== undefined && summaryOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>summaryOnly,
        'summary_only'
      );
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page'
      );
    }
    if (count !== undefined && count !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>count,
        'count'
      );
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>dateFrom,
        'date_from'
      );
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>dateTo,
        'date_to'
      );
    }
    if (number !== undefined && number !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>number,
        'number'
      );
    }
    if (notPaidOnly !== undefined && notPaidOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notPaidOnly,
        'not_paid_only'
      );
    }
    if (notInRouteOnly !== undefined && notInRouteOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notInRouteOnly,
        'not_in_route_only'
      );
    }
    if (notCompletedOnly !== undefined && notCompletedOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notCompletedOnly,
        'not_completed_only'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/online_store/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/orders`;
    return this.httpClient.request<OrderListResult>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return order object
   * @param orderId Order Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiOnlineStoreOrder(
    orderId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Order>;
  public incustControllersTermApiOnlineStoreOrder(
    orderId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Order>>;
  public incustControllersTermApiOnlineStoreOrder(
    orderId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Order>>;
  public incustControllersTermApiOnlineStoreOrder(
    orderId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling incustControllersTermApiOnlineStoreOrder.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/online_store/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}`;
    return this.httpClient.request<Order>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return orders list
   * @param orderType Order type filtering if needed
   * @param summaryOnly Return only summary information
   * @param page List page number
   * @param count Transactions count in page
   * @param dateFrom Date and time filter from in RFC3339
   * @param dateTo Date and time to from in RFC3339
   * @param number Order number if need
   * @param notPaidOnly Return only not paid orders
   * @param notInRouteOnly Return only orders that not in route
   * @param notCompletedOnly Return only orders that not completed (doesn\&#39;t have the status \&#39;delivered\&#39; or \&#39;canceled\&#39;)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiOnlineStoreOrders(
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<OrderListResult>;
  public incustControllersTermApiOnlineStoreOrders(
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<OrderListResult>>;
  public incustControllersTermApiOnlineStoreOrders(
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<OrderListResult>>;
  public incustControllersTermApiOnlineStoreOrders(
    orderType?: 'all' | 'online-store' | 'fuel-taxi',
    summaryOnly?: boolean,
    page?: number,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    number?: string,
    notPaidOnly?: boolean,
    notInRouteOnly?: boolean,
    notCompletedOnly?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (orderType !== undefined && orderType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>orderType,
        'order_type'
      );
    }
    if (summaryOnly !== undefined && summaryOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>summaryOnly,
        'summary_only'
      );
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page'
      );
    }
    if (count !== undefined && count !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>count,
        'count'
      );
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>dateFrom,
        'date_from'
      );
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>dateTo,
        'date_to'
      );
    }
    if (number !== undefined && number !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>number,
        'number'
      );
    }
    if (notPaidOnly !== undefined && notPaidOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notPaidOnly,
        'not_paid_only'
      );
    }
    if (notInRouteOnly !== undefined && notInRouteOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notInRouteOnly,
        'not_in_route_only'
      );
    }
    if (notCompletedOnly !== undefined && notCompletedOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>notCompletedOnly,
        'not_completed_only'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/online_store/orders`;
    return this.httpClient.request<OrderListResult>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }
}
