/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CorporateAccountAccessLimits } from './corporateAccountAccessLimits';

export interface PriceCustomerSpecialAccountAccess {
  /**
   * Customer access id
   */
  id?: string | null;
  limits?: CorporateAccountAccessLimits;
  /**
   * Odometer value needed flag
   */
  odometer?: PriceCustomerSpecialAccountAccess.OdometerEnum;
  /**
   * Additional PIN-code for access to the account
   */
  security_code?: string | null;
  /**
   * Vehicle QR Code / Tag needed flag
   */
  vehicle_id?: PriceCustomerSpecialAccountAccess.VehicleIdEnum | null;
  /**
   * Vehicle QR Code / Tag needed to be verify by Vehicles/Inventory list
   */
  vehicle_id_verify?: number | null;
}
export namespace PriceCustomerSpecialAccountAccess {
  export type OdometerEnum = 'no' | 'yes' | 'possible';
  export const OdometerEnum = {
    No: 'no' as OdometerEnum,
    Yes: 'yes' as OdometerEnum,
    Possible: 'possible' as OdometerEnum
  };
  export type VehicleIdEnum = 'no' | 'yes' | 'possible';
  export const VehicleIdEnum = {
    No: 'no' as VehicleIdEnum,
    Yes: 'yes' as VehicleIdEnum,
    Possible: 'possible' as VehicleIdEnum
  };
}
