import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './http-interceptors/token.interceptor';
import { HttpErrorInterceptor } from './http-interceptors/http-error.interceptor';
import { ApiTermModule } from '@incust/api-term';

export interface AuthOptions {
  language?: string;
  applicationType?: string;
  applicationVersion?: string;
  applicationPackageName?: string;
  applicationLocalAddr?: string;
}

export const AUTH_OPTIONS = new InjectionToken<AuthOptions>('AUTH_OPTIONS');

@NgModule({
  imports: [ApiTermModule],
  providers: []
})
export class AuthModule {
  static forRoot(authOptions?: AuthOptions): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AUTH_OPTIONS,
          useValue: authOptions || {}
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true
        }
      ]
    };
  }
}
