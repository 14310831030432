/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TerminalVehicleFuelSettings } from './terminalVehicleFuelSettings';
import { TerminalExternalDevicesSettings } from './terminalExternalDevicesSettings';
import { Pos } from './pos';
import { UserQrCodeSettings } from './userQrCodeSettings';
import { LoyaltySettings } from './loyaltySettings';
import { SystemParams } from './systemParams';
import { TerminalScreensaverSettings } from './terminalScreensaverSettings';
import { CustomerFeedbackSettings } from './customerFeedbackSettings';
import { Loyalty } from './loyalty';
import { MobileAppPaymentSettings } from './mobileAppPaymentSettings';
import { PriceGroupSettings } from './priceGroupSettings';

export interface Settings {
  application_settings?: object;
  /**
   * Start work in the Register by customer identification
   */
  begin_work_with_customer_select?: number | null;
  /**
   * Business code
   */
  business_code?: string;
  /**
   * Currensy ISO code
   */
  currency?: string;
  customer_feedback_settings?: CustomerFeedbackSettings;
  /**
   * Terminal has active display
   */
  display_active?: number;
  /**
   * Display type
   */
  display_type?: Settings.DisplayTypeEnum;
  /**
   * Terminal support equipment processing
   */
  equipment?: boolean;
  /**
   * send last event status period in seconds (if zero - only on application events)
   */
  events_status_send_period?: number;
  external_devices_settings?: TerminalExternalDevicesSettings;
  /**
   * Terminal External ID (up to 128 symbols)
   */
  external_id?: string;
  /**
   * Terminal support fuel processing
   */
  fuel?: boolean;
  /**
   * Only preset recommended values allowed
   */
  goods_preset_recommnded_values_only?: number;
  /**
   * Terminal ID
   */
  id?: string;
  /**
   * Terminal login
   */
  login?: string;
  loyalty?: Loyalty;
  /**
   * Replaced by mobileapp_payment_settings (DEPRECATED)
   */
  loyalty_payment_settings?: Array<MobileAppPaymentSettings>;
  loyalty_settings?: LoyaltySettings;
  /**
   * MobileApp payment settings
   */
  mobileapp_payment_settings?: Array<MobileAppPaymentSettings>;
  /**
   * Terminal phone
   */
  phone?: string;
  pos?: Pos;
  poster_settings?: object;
  price_group_settings?: PriceGroupSettings;
  screensaver_settings?: TerminalScreensaverSettings;
  /**
   * Terminal required staff sign in for work
   */
  staff_sign_in_required?: number;
  system_params?: SystemParams;
  /**
   * Terminal title
   */
  title?: string;
  /**
   * Token for next authorization requests (optional, only for /token request)
   */
  token?: string;
  /**
   * Terminal type
   */
  type?: Settings.TypeEnum;
  /**
   * Customer consent required for working
   */
  user_consent_required?: boolean;
  user_qrcode_settings?: UserQrCodeSettings;
  vehicle_fuel_settings?: TerminalVehicleFuelSettings;
}
export namespace Settings {
  export type DisplayTypeEnum =
    | 'customer-identity'
    | 'gift-redeem'
    | 'login-pad'
    | 'steward';
  export const DisplayTypeEnum = {
    CustomerIdentity: 'customer-identity' as DisplayTypeEnum,
    GiftRedeem: 'gift-redeem' as DisplayTypeEnum,
    LoginPad: 'login-pad' as DisplayTypeEnum,
    Steward: 'steward' as DisplayTypeEnum
  };
  export type TypeEnum =
    | 'register'
    | 'steward'
    | 'validator'
    | 'prepaid-services'
    | 'road-tanker-driver'
    | 'kiosk'
    | 'kiosk-and-register';
  export const TypeEnum = {
    Register: 'register' as TypeEnum,
    Steward: 'steward' as TypeEnum,
    Validator: 'validator' as TypeEnum,
    PrepaidServices: 'prepaid-services' as TypeEnum,
    RoadTankerDriver: 'road-tanker-driver' as TypeEnum,
    Kiosk: 'kiosk' as TypeEnum,
    KioskAndRegister: 'kiosk-and-register' as TypeEnum
  };
}
