/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CheckService {
  /**
   * Unique Id of additional service by code (optional)
   */
  additional_service_id?: string | null;
  /**
   * Service amount
   */
  amount?: number | null;
  /**
   * Service code
   */
  code: string;
  /**
   * Service cost (for unit of value)
   */
  cost?: number | null;
  /**
   * Number of decimal digits in cost and amount values
   */
  decimal_digits?: number | null;
  /**
   * Service title
   */
  title?: string | null;
  /**
   * Service type
   */
  type?: CheckService.TypeEnum | null;
  /**
   * Base value for calculate service cost and amount (distance, etc.)
   */
  value?: number | null;
}
export namespace CheckService {
  export type TypeEnum = 'delivery' | 'commission';
  export const TypeEnum = {
    Delivery: 'delivery' as TypeEnum,
    Commission: 'commission' as TypeEnum
  };
}
