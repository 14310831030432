/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankCardExternalInfo } from './bankCardExternalInfo';

export interface PaymentForProcess {
  /**
   * Payment amount
   */
  amount?: number;
  bank_card_external?: BankCardExternalInfo;
  /**
   * Currensy ISO code (depends on transaction type)
   */
  currency?: string;
  /**
   * Payment Id
   */
  id?: string;
  /**
   * Payment operation type (depends on payment_system_type)
   */
  operation_type?: PaymentForProcess.OperationTypeEnum;
  /**
   * Payment system type (depends on payment type)
   */
  payment_system_type?: PaymentForProcess.PaymentSystemTypeEnum;
  /**
   * Payment processing result (depends on payment_system_type)
   */
  processing_result?: object | null;
  /**
   * Operation status
   */
  status?: PaymentForProcess.StatusEnum;
  /**
   * Transaction Id
   */
  transaction_id?: string;
  /**
   * Payment type
   */
  type?: PaymentForProcess.TypeEnum;
}
export namespace PaymentForProcess {
  export type OperationTypeEnum =
    | 'authorize'
    | 'completion'
    | 'refund'
    | 'cancel';
  export const OperationTypeEnum = {
    Authorize: 'authorize' as OperationTypeEnum,
    Completion: 'completion' as OperationTypeEnum,
    Refund: 'refund' as OperationTypeEnum,
    Cancel: 'cancel' as OperationTypeEnum
  };
  export type PaymentSystemTypeEnum = 'amp' | 'pax';
  export const PaymentSystemTypeEnum = {
    Amp: 'amp' as PaymentSystemTypeEnum,
    Pax: 'pax' as PaymentSystemTypeEnum
  };
  export type StatusEnum =
    | 'inprocess'
    | 'success'
    | 'fail'
    | 'canceled'
    | 'error'
    | 'authorized';
  export const StatusEnum = {
    Inprocess: 'inprocess' as StatusEnum,
    Success: 'success' as StatusEnum,
    Fail: 'fail' as StatusEnum,
    Canceled: 'canceled' as StatusEnum,
    Error: 'error' as StatusEnum,
    Authorized: 'authorized' as StatusEnum
  };
  export type TypeEnum =
    | 'cash'
    | 'bank-card'
    | 'bank-card-external'
    | 'mobile-app'
    | 'special-account';
  export const TypeEnum = {
    Cash: 'cash' as TypeEnum,
    BankCard: 'bank-card' as TypeEnum,
    BankCardExternal: 'bank-card-external' as TypeEnum,
    MobileApp: 'mobile-app' as TypeEnum,
    SpecialAccount: 'special-account' as TypeEnum
  };
}
