/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TerminalTokenByPosterRequest {
  /**
   * Poster Company Account
   */
  account: string;
  /**
   * Poster Application ID
   */
  application_id: string;
  /**
   * Poster Customer Number
   */
  client_number: string;
  /**
   * Poster Location Id
   */
  spot_id: number;
  /**
   * Poster Register Id
   */
  spot_tablet_id: number;
}
