/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AllTypeCustomer {
  /**
   * Customer email
   */
  email?: string;
  /**
   * Customer external id (if supported)
   */
  external_id?: string;
  /**
   * Customer Id
   */
  id?: string;
  /**
   * Customer name
   */
  name?: string;
  /**
   * Customer phone number
   */
  phone?: string;
  /**
   * Customer type
   */
  type?: AllTypeCustomer.TypeEnum;
}
export namespace AllTypeCustomer {
  export type TypeEnum = 'customer' | 'corporate-customer';
  export const TypeEnum = {
    Customer: 'customer' as TypeEnum,
    CorporateCustomer: 'corporate-customer' as TypeEnum
  };
}
