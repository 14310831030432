/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AccountLimits {
  /**
   * Amount of funds on the account available for using (null if unlimited)
   */
  account_available_amount?: number | null;
  /**
   * Customer account day limit (if 0 - unlimited)
   */
  day_limit?: number | null;
  /**
   * Customer account day available amount, not for unlimited account (null if unlimited)
   */
  day_limit_available?: number | null;
  /**
   * Customer account day used amount
   */
  day_limit_used?: number | null;
  /**
   * Customer account month limit (if 0 - unlimited)
   */
  month_limit?: number | null;
  /**
   * Customer account month available amount, not for unlimited account (null if unlimited)
   */
  month_limit_available?: number | null;
  /**
   * Customer account month used amount
   */
  month_limit_used?: number | null;
}
