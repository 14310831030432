/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface IncustControllersTermApiPhoneVerificationRequest {
  /**
   * Phone number. Minimum 3 chars.
   */
  phone: string;
  /**
   * Secure pin code
   */
  pincode?: string;
}
