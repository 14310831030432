/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImageObject } from './imageObject';

export interface UserVehicle {
  /**
   * Vehicle color
   */
  color?: string;
  /**
   * Fuel cap locked flag
   */
  fuel_tank_cap_locked?: number;
  /**
   * Vehicle ID
   */
  id?: string | null;
  /**
   * Vehicle model
   */
  model?: string | null;
  photo?: ImageObject;
  /**
   * Vehicle plate number
   */
  plate_number?: string | null;
  /**
   * Vehicle type
   */
  type?: UserVehicle.TypeEnum;
}
export namespace UserVehicle {
  export type TypeEnum = 'car' | 'suv' | 'truck' | 'van' | 'equipment';
  export const TypeEnum = {
    Car: 'car' as TypeEnum,
    Suv: 'suv' as TypeEnum,
    Truck: 'truck' as TypeEnum,
    Van: 'van' as TypeEnum,
    Equipment: 'equipment' as TypeEnum
  };
}
