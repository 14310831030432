/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Turnover data
 */
export interface CustomerRateInfo {
  /**
   * Currency ISO code
   */
  currency?: string;
  /**
   * turnover amount
   */
  turnover?: number;
}
