/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RouteOperationProcessData {
  /**
   * Assistance trouble type (only for assistance operation required)
   */
  assistance_type?: RouteOperationProcessData.AssistanceTypeEnum | null;
  /**
   * Operation/Failure comment
   */
  comment?: string | null;
}
export namespace RouteOperationProcessData {
  export type AssistanceTypeEnum = 'fuel-cap-locked' | 'vehicle-not-found';
  export const AssistanceTypeEnum = {
    FuelCapLocked: 'fuel-cap-locked' as AssistanceTypeEnum,
    VehicleNotFound: 'vehicle-not-found' as AssistanceTypeEnum
  };
}
