/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RouteOperationOrder {
  /**
   * Route date
   */
  date: string;
  /**
   * Order Id
   */
  order_id: string;
  /**
   * Scheduled time in HH:mm / HH:mm:ss format
   */
  scheduled: string;
}
