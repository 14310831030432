/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PriceGroupTaxRate {
  /**
   * Currency ISO code (readonly)
   */
  currency?: string | null;
  /**
   * Tax rate default value in the Price Group (readonly)
   */
  default_value?: number | null;
  /**
   * Goods item ID
   */
  item_id?: string | null;
  /**
   * Price group ID
   */
  price_group_id?: string | null;
  /**
   * Tax classification
   */
  tax_classification?: PriceGroupTaxRate.TaxClassificationEnum | null;
  /**
   * Tax code
   */
  tax_code?: string | null;
  /**
   * Tax rate decimal digits (readonly)
   */
  tax_decimal_digits?: number;
  /**
   * Tax ID
   */
  tax_id?: string | null;
  /**
   * Tax title (readonly)
   */
  tax_title?: string | null;
  /**
   * Tax rate value type (readonly)
   */
  tax_type?: PriceGroupTaxRate.TaxTypeEnum | null;
  /**
   * Tax rate actual value in the Price Group
   */
  value?: number | null;
}
export namespace PriceGroupTaxRate {
  export type TaxClassificationEnum = 'base-tax' | 'sales-tax';
  export const TaxClassificationEnum = {
    BaseTax: 'base-tax' as TaxClassificationEnum,
    SalesTax: 'sales-tax' as TaxClassificationEnum
  };
  export type TaxTypeEnum = 'amount' | 'percentage';
  export const TaxTypeEnum = {
    Amount: 'amount' as TaxTypeEnum,
    Percentage: 'percentage' as TaxTypeEnum
  };
}
