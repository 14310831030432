/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CardCategoryExtendedSettings {
  /**
   * Limit bonus points redeem in percentage of check amount
   */
  bonus_payment_limit?: number | null;
  /**
   * Bonus points payment settings source (optional)
   */
  bonus_payment_settings_source?: CardCategoryExtendedSettings.BonusPaymentSettingsSourceEnum | null;
  /**
   * Type of payment by bonus points
   */
  bonus_payment_type?: CardCategoryExtendedSettings.BonusPaymentTypeEnum | null;
}
export namespace CardCategoryExtendedSettings {
  export type BonusPaymentSettingsSourceEnum = 'loyalty' | 'card-category';
  export const BonusPaymentSettingsSourceEnum = {
    Loyalty: 'loyalty' as BonusPaymentSettingsSourceEnum,
    CardCategory: 'card-category' as BonusPaymentSettingsSourceEnum
  };
  export type BonusPaymentTypeEnum =
    | 'full-check'
    | 'partial'
    | 'partial-rounded';
  export const BonusPaymentTypeEnum = {
    FullCheck: 'full-check' as BonusPaymentTypeEnum,
    Partial: 'partial' as BonusPaymentTypeEnum,
    PartialRounded: 'partial-rounded' as BonusPaymentTypeEnum
  };
}
