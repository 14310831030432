/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CorporateCustomerCardInfo } from './corporateCustomerCardInfo';
import { CardInfo } from './cardInfo';
import { Goods } from './goods';

export interface Tank {
  /**
   * Tank is active flag
   */
  active?: number;
  /**
   * Title
   */
  address?: string | null;
  /**
   * Tank unique code
   */
  code?: string | null;
  corporate_customer?: CorporateCustomerCardInfo;
  customer?: CardInfo;
  goods?: Goods;
  /**
   * Linked product code (optional, only for linked tank data)
   */
  goods_code?: string | null;
  /**
   * Tank id
   */
  id?: string | null;
  /**
   * Latitude in decimal degrees
   */
  latitude?: number | null;
  /**
   * Tank linked with Business flag
   */
  linked?: boolean;
  /**
   * Longitude in decimal degrees
   */
  longitude?: number | null;
  /**
   * Tank owner email (User, Corporate User depends on owner_type)
   */
  owner_email?: string;
  /**
   * Tank owner external Id (User, Corporate User depends on owner_type)
   */
  owner_external_id?: string;
  /**
   * Tank owner Id (User, Corporate User depends on owner_type)
   */
  owner_id?: string;
  /**
   * Tank owner name (User, Corporate User depends on owner_type)
   */
  owner_name?: string;
  /**
   * Tank owner phone (User, Corporate User depends on owner_type)
   */
  owner_phone?: string;
  /**
   * Tank owner type
   */
  owner_type?: Tank.OwnerTypeEnum;
  /**
   * Product title (for information only)
   */
  product_title?: string | null;
  /**
   * Current product volume
   */
  product_volume?: number | null;
  /**
   * Title
   */
  title?: string | null;
  /**
   * Current product volume
   */
  ullage_volume?: number | null;
  /**
   * Measuring units
   */
  unit?: string | null;
  /**
   * Tank volume
   */
  volume?: number | null;
}
export namespace Tank {
  export type OwnerTypeEnum = 'customer' | 'corporate-customer';
  export const OwnerTypeEnum = {
    Customer: 'customer' as OwnerTypeEnum,
    CorporateCustomer: 'corporate-customer' as OwnerTypeEnum
  };
}
