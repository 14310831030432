/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type TransactionType =
  | 'reserve'
  | 'presale'
  | 'sale'
  | 'cancel'
  | 'redeem-giftcard'
  | 'bonus-add'
  | 'special-account-refill'
  | 'coupon-sale'
  | 'authorize-payment'
  | 'bonus-write-offs'
  | 'special-account-write-offs';

export const TransactionType = {
  Reserve: 'reserve' as TransactionType,
  Presale: 'presale' as TransactionType,
  Sale: 'sale' as TransactionType,
  Cancel: 'cancel' as TransactionType,
  RedeemGiftcard: 'redeem-giftcard' as TransactionType,
  BonusAdd: 'bonus-add' as TransactionType,
  SpecialAccountRefill: 'special-account-refill' as TransactionType,
  CouponSale: 'coupon-sale' as TransactionType,
  AuthorizePayment: 'authorize-payment' as TransactionType,
  BonusWriteOffs: 'bonus-write-offs' as TransactionType,
  SpecialAccountWriteOffs: 'special-account-write-offs' as TransactionType
};
