/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CheckTank } from './checkTank';
import { Coupon } from './coupon';
import { TransactionType } from './transactionType';
import { TransactionReceiptActions } from './transactionReceiptActions';
import { BusinessStaff } from './businessStaff';
import { CorporateVehicleInfo } from './corporateVehicleInfo';
import { LoyaltyRule } from './loyaltyRule';
import { CheckUserExternalFormRelations } from './checkUserExternalFormRelations';
import { SpecialAccountCharge } from './specialAccountCharge';
import { CheckMargins } from './checkMargins';
import { BillingInfo } from './billingInfo';
import { OdometerUnitType } from './odometerUnitType';
import { IdType } from './idType';
import { SpecialAccount } from './specialAccount';
import { Payment } from './payment';
import { BonusAdded } from './bonusAdded';
import { CheckItem } from './checkItem';
import { Pos } from './pos';
import { CheckBusiness } from './checkBusiness';
import { CheckTaxes } from './checkTaxes';
import { CheckService } from './checkService';
import { Loyalty } from './loyalty';

export interface Check {
  /**
   * Amount of check
   */
  amount: number | null;
  /**
   * Number of decimal digits in amount value
   */
  amount_decimal_digits?: number | null;
  /**
   * Amount to pay
   */
  amount_to_pay?: number | null;
  /**
   * AVI (automatic vehicle identification) Id value (optional)
   */
  avi_id?: string | null;
  billing_info?: BillingInfo;
  bonuses_added?: Array<BonusAdded> | null;
  /**
   * Added bonuses amount
   */
  bonuses_added_amount?: number | null;
  /**
   * Number of decimal digits in bonuses value
   */
  bonuses_decimal_digits?: number | null;
  /**
   * Redeemed bonuses amount
   */
  bonuses_redeemed_amount?: number | null;
  business?: CheckBusiness;
  business_staff?: BusinessStaff;
  calculated_margins?: CheckMargins;
  calculated_taxes?: CheckTaxes;
  /**
   * User phone number. Minimum 3 chars
   */
  cardotppassword?: string | null;
  check_items?: Array<CheckItem> | null;
  /**
   * Services added on check (optional)
   */
  check_services?: Array<CheckService> | null;
  /**
   * Comment
   */
  comment?: string | null;
  /**
   * Corporate Customer ID
   */
  corporate_customer_id?: string | null;
  /**
   * Corporate Customer Account ID
   */
  corporate_customer_special_account_id?: string | null;
  corporate_vehicle?: CorporateVehicleInfo;
  /**
   * Customer user ID
   */
  customer_id?: string | null;
  /**
   * Amount of discount
   */
  discount_amount?: number | null;
  emitted_coupons?: Array<Coupon> | null;
  /**
   * Any custom external data (optional)
   */
  external_additional_info?: object | null;
  /**
   * External check identifier
   */
  external_check_id?: string | null;
  /**
   * External check identifier scope
   */
  external_check_id_scope?: Check.ExternalCheckIdScopeEnum | null;
  /**
   * User identifier (phone number, card code, QR code). Minimum 3 chars
   */
  id?: string | null;
  id_type?: IdType;
  implemented_rules?: Array<LoyaltyRule>;
  loyalty?: Loyalty;
  /**
   * Specified the rules manually selected at the terminal
   */
  manual_rules?: boolean | null;
  /**
   * Odometer/engine hours value, up to two decimal digits (optional)
   */
  odometer?: number | null;
  odometer_unit?: OdometerUnitType;
  /**
   * Online store order id
   */
  online_store_order_id?: string | null;
  /**
   * Currency ISO code, special account Id or corporate special account access
   */
  payment_id: string | null;
  /**
   * Payment type
   */
  payment_type: Check.PaymentTypeEnum | null;
  payments?: Array<Payment> | null;
  pos?: Pos;
  /**
   * Preliminary Transaction Id (optional, only if exists)
   */
  preliminary_transaction_id?: string | null;
  preliminary_transaction_type?: TransactionType;
  redeemed_coupons?: Array<Coupon> | null;
  /**
   * Route operation Id
   */
  route_operation_id?: string | null;
  /**
   * Added on check services total amount (optional)
   */
  services_amount?: number | null;
  /**
   * Shipping amount (optional)
   */
  shipping_amount?: number | null;
  /**
   * Skip notifications
   */
  skip_message?: boolean | null;
  special_account?: SpecialAccount;
  /**
   * Odometer value required flag for operating with special account (response only)
   */
  special_account_odometer_required?: Check.SpecialAccountOdometerRequiredEnum | null;
  /**
   * PIN code for operating with special account (request only)
   */
  special_account_pin?: string | null;
  /**
   * PIN required flag for operating with special account (response only)
   */
  special_account_pin_required?: boolean | null;
  /**
   * Verify special account required values flag (only for process_check, ignored in othe methods)
   */
  special_account_required_values_verify?: boolean | null;
  /**
   * VIN (other vehicle identifier) value required flag for operating with special account (response only)
   */
  special_account_vehicle_id_required?: Check.SpecialAccountVehicleIdRequiredEnum | null;
  special_accounts_charges?: Array<SpecialAccountCharge> | null;
  /**
   * Number of decimal digits in special account value
   */
  special_accounts_decimal_digits?: number | null;
  /**
   * Sum of amount and shipping_amount and services_amount (optional, readonly)
   */
  summary_amount?: number | null;
  /**
   * Tanks Data (optional)
   */
  tanks?: Array<CheckTank> | null;
  terminal_odometer_measurement_units?: OdometerUnitType;
  /**
   * Transaction canceled flag
   */
  transaction_cancelled?: number | null;
  /**
   * Transaction ID
   */
  transaction_id?: string | null;
  transaction_receipt_actions?: TransactionReceiptActions;
  transaction_subtype?: TransactionType;
  transaction_type?: TransactionType;
  user_external_forms?: CheckUserExternalFormRelations;
  /**
   * Qr Code / Tag of vehicle or equipment value (optional)
   */
  vehicle_id?: string | null;
}
export namespace Check {
  export type ExternalCheckIdScopeEnum =
    | 'system'
    | 'business'
    | 'loyalty'
    | 'pos'
    | 'terminal';
  export const ExternalCheckIdScopeEnum = {
    System: 'system' as ExternalCheckIdScopeEnum,
    Business: 'business' as ExternalCheckIdScopeEnum,
    Loyalty: 'loyalty' as ExternalCheckIdScopeEnum,
    Pos: 'pos' as ExternalCheckIdScopeEnum,
    Terminal: 'terminal' as ExternalCheckIdScopeEnum
  };
  export type PaymentTypeEnum =
    | 'currency'
    | 'special-account'
    | 'corporate-special-account-access'
    | 'retail-special-account-access';
  export const PaymentTypeEnum = {
    Currency: 'currency' as PaymentTypeEnum,
    SpecialAccount: 'special-account' as PaymentTypeEnum,
    CorporateSpecialAccountAccess:
      'corporate-special-account-access' as PaymentTypeEnum,
    RetailSpecialAccountAccess:
      'retail-special-account-access' as PaymentTypeEnum
  };
  export type SpecialAccountOdometerRequiredEnum = 'no' | 'yes' | 'possible';
  export const SpecialAccountOdometerRequiredEnum = {
    No: 'no' as SpecialAccountOdometerRequiredEnum,
    Yes: 'yes' as SpecialAccountOdometerRequiredEnum,
    Possible: 'possible' as SpecialAccountOdometerRequiredEnum
  };
  export type SpecialAccountVehicleIdRequiredEnum = 'no' | 'yes' | 'possible';
  export const SpecialAccountVehicleIdRequiredEnum = {
    No: 'no' as SpecialAccountVehicleIdRequiredEnum,
    Yes: 'yes' as SpecialAccountVehicleIdRequiredEnum,
    Possible: 'possible' as SpecialAccountVehicleIdRequiredEnum
  };
}
