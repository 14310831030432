/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type PriceExplainOriginValueType = 'percentage' | 'amount';

export const PriceExplainOriginValueType = {
  Percentage: 'percentage' as PriceExplainOriginValueType,
  Amount: 'amount' as PriceExplainOriginValueType
};
