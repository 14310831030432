/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AdditionalServiceCostValue {
  /**
   * Threshold
   */
  threshold?: number | null;
  /**
   * Value type
   */
  type?: AdditionalServiceCostValue.TypeEnum | null;
  /**
   * Value
   */
  value?: number | null;
}
export namespace AdditionalServiceCostValue {
  export type TypeEnum = 'amount' | 'percentage' | 'multiplier';
  export const TypeEnum = {
    Amount: 'amount' as TypeEnum,
    Percentage: 'percentage' as TypeEnum,
    Multiplier: 'multiplier' as TypeEnum
  };
}
