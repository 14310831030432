/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FuelControllerConfiguration } from './fuelControllerConfiguration';
import { PriceTaxes } from './priceTaxes';
import { PriceMargins } from './priceMargins';
import { OdometerUnitType } from './odometerUnitType';
import { FuelTank } from './fuelTank';

export interface JobFuel {
  /**
   * AVI (automatic vehicle identification) Id value (optional)
   */
  avi_id?: string | null;
  /**
   * Fuel amount captured by check
   */
  captured_amount?: number | null;
  /**
   * Fuel price captured by check
   */
  captured_price?: number | null;
  /**
   * Fuel volume captured by check
   */
  captured_volume?: number | null;
  /**
   * Controller connection failed flag (Exists and set to true only if connection with controller failed)
   */
  connection_failed?: boolean | null;
  /**
   * Internal controller ID
   */
  controller?: string | null;
  controller_configuration?: FuelControllerConfiguration;
  /**
   * Controller Id
   */
  controller_id?: string | null;
  /**
   * Controller type
   */
  controller_type?: JobFuel.ControllerTypeEnum | null;
  /**
   * Error message (Exists only if controller send error message)
   */
  error_message?: string | null;
  /**
   * Job money amount
   */
  filled_amount?: number | null;
  /**
   * Job filled volume
   */
  filled_volume?: number | null;
  /**
   * Filling preset dose in volume units (for preset type Volume) or in currency units (for preset type Amount)
   */
  filling_doze?: number | null;
  /**
   * Filling fuel price
   */
  filling_price?: number | null;
  /**
   * Filling price currency ISO code (Only if filling_price_type is \'currency\')
   */
  filling_price_currency?: string | null;
  filling_price_margins?: PriceMargins;
  /**
   * Filling price special account Id (Only if filling_price_type is \'special-account\')
   */
  filling_price_special_account_id?: string | null;
  filling_price_taxes?: PriceTaxes;
  /**
   * Filling price type
   */
  filling_price_type?: JobFuel.FillingPriceTypeEnum | null;
  /**
   * Filling price unit - quantity or currency ISO code (Only if filling_price_type is \'special-account\')
   */
  filling_price_unit?: string | null;
  /**
   * Filling preset type
   */
  filling_type?: JobFuel.FillingTypeEnum | null;
  /**
   * Goods code
   */
  goods_code?: string | null;
  /**
   * Grade Id
   */
  grade_id?: string | null;
  /**
   * Internal nozzle ID / Pump nozzle
   */
  nozzle?: string | null;
  /**
   * Nozzle Id
   */
  nozzle_id: string | null;
  /**
   * Odometer/engine hours value, up to two decimal digits (optional)
   */
  odometer?: number | null;
  odometer_unit?: OdometerUnitType;
  /**
   * Internal pump ID / Pump number
   */
  pump?: string | null;
  /**
   * Pump authorize confirmation type
   */
  pump_authorize_confirmation?: JobFuel.PumpAuthorizeConfirmationEnum | null;
  /**
   * Pump Id
   */
  pump_id?: string | null;
  /**
   * Fuel filling status
   */
  status?: JobFuel.StatusEnum | null;
  /**
   * Internal tank ID / Tank number
   */
  tank?: string | null;
  tank_details?: FuelTank;
  /**
   * Tank Id
   */
  tank_id?: string | null;
  /**
   * Qr Code / Tag of vehicle or equipment value (optional)
   */
  vehicle_id?: string | null;
}
export namespace JobFuel {
  export type ControllerTypeEnum = 'pts-2';
  export const ControllerTypeEnum = {
    Pts2: 'pts-2' as ControllerTypeEnum
  };
  export type FillingPriceTypeEnum = 'special-account' | 'currency';
  export const FillingPriceTypeEnum = {
    SpecialAccount: 'special-account' as FillingPriceTypeEnum,
    Currency: 'currency' as FillingPriceTypeEnum
  };
  export type FillingTypeEnum = 'amount' | 'volume';
  export const FillingTypeEnum = {
    Amount: 'amount' as FillingTypeEnum,
    Volume: 'volume' as FillingTypeEnum
  };
  export type PumpAuthorizeConfirmationEnum =
    | 'selected-nozzle-up'
    | 'without-nozzle-confirmation';
  export const PumpAuthorizeConfirmationEnum = {
    SelectedNozzleUp: 'selected-nozzle-up' as PumpAuthorizeConfirmationEnum,
    WithoutNozzleConfirmation:
      'without-nozzle-confirmation' as PumpAuthorizeConfirmationEnum
  };
  export type StatusEnum =
    | 'waiting-for-process'
    | 'pump-is-offline'
    | 'pump-is-busy'
    | 'nozzle-not-taken-up'
    | 'wrong-nozzle-taken-up'
    | 'ready-for-filling'
    | 'filling'
    | 'filling-stopping'
    | 'filling-finished'
    | 'filling-failed';
  export const StatusEnum = {
    WaitingForProcess: 'waiting-for-process' as StatusEnum,
    PumpIsOffline: 'pump-is-offline' as StatusEnum,
    PumpIsBusy: 'pump-is-busy' as StatusEnum,
    NozzleNotTakenUp: 'nozzle-not-taken-up' as StatusEnum,
    WrongNozzleTakenUp: 'wrong-nozzle-taken-up' as StatusEnum,
    ReadyForFilling: 'ready-for-filling' as StatusEnum,
    Filling: 'filling' as StatusEnum,
    FillingStopping: 'filling-stopping' as StatusEnum,
    FillingFinished: 'filling-finished' as StatusEnum,
    FillingFailed: 'filling-failed' as StatusEnum
  };
}
