/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RouteOperationModify {
  /**
   * Route date
   */
  date?: string | null;
  /**
   * Operation Id
   */
  id: string;
  /**
   * Scheduled time in HH:mm / HH:mm:ss format
   */
  scheduled?: string | null;
}
