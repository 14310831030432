/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BusinessStaff } from './businessStaff';

export interface EventStatus {
  business_staff?: BusinessStaff;
  /**
   * Event Id (readonly)
   */
  id?: string | null;
  /**
   * Event UTC processed date-time (readonly)
   */
  processed?: string | null;
  /**
   * Event Status
   */
  status?: EventStatus.StatusEnum | null;
}
export namespace EventStatus {
  export type StatusEnum =
    | 'terminal-sign-in-awaiting'
    | 'staff-sign-in-awaiting'
    | 'customer-awaiting'
    | 'customer-processing'
    | 'refueling-started'
    | 'refueling-finished'
    | 'terminal-settings-processing'
    | 'customer-tap-splashscreen'
    | 'payment-process';
  export const StatusEnum = {
    TerminalSignInAwaiting: 'terminal-sign-in-awaiting' as StatusEnum,
    StaffSignInAwaiting: 'staff-sign-in-awaiting' as StatusEnum,
    CustomerAwaiting: 'customer-awaiting' as StatusEnum,
    CustomerProcessing: 'customer-processing' as StatusEnum,
    RefuelingStarted: 'refueling-started' as StatusEnum,
    RefuelingFinished: 'refueling-finished' as StatusEnum,
    TerminalSettingsProcessing: 'terminal-settings-processing' as StatusEnum,
    CustomerTapSplashscreen: 'customer-tap-splashscreen' as StatusEnum,
    PaymentProcess: 'payment-process' as StatusEnum
  };
}
