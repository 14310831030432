/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EquipmentJobsRec } from './equipmentJobsRec';

export interface EquipmentRec {
  /**
   * Is available available for operations (optional)
   */
  available?: boolean | null;
  /**
   * Controller status
   */
  controller_status?: EquipmentRec.ControllerStatusEnum | null;
  /**
   * Equipment ID
   */
  id?: string | null;
  jobs?: EquipmentJobsRec;
  /**
   * Is monitoring of working equipment status turned on
   */
  monitor_working_status?: boolean | null;
  /**
   * Equipment Title
   */
  title?: string | null;
  /**
   * Equipment type
   */
  type?: EquipmentRec.TypeEnum | null;
}
export namespace EquipmentRec {
  export type ControllerStatusEnum =
    | 'connection-failed'
    | 'available'
    | 'unavailable';
  export const ControllerStatusEnum = {
    ConnectionFailed: 'connection-failed' as ControllerStatusEnum,
    Available: 'available' as ControllerStatusEnum,
    Unavailable: 'unavailable' as ControllerStatusEnum
  };
  export type TypeEnum = 'car-wash';
  export const TypeEnum = {
    CarWash: 'car-wash' as TypeEnum
  };
}
