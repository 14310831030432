/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CouponBatchAlias } from './couponBatchAlias';
import { CouponBatchLink } from './couponBatchLink';

export interface CouponBatch {
  alias?: CouponBatchAlias;
  /**
   * Automatically redeem on wallet add event (optional)
   */
  auto_redeem_on_add_to_wallet?: number | null;
  /**
   * Business Id
   */
  business_id?: string | null;
  /**
   * Coupon emission price (optional)
   */
  customers_price_amount?: number | null;
  /**
   * Coupon emission price currency (optional)
   */
  customers_price_currency?: string | null;
  /**
   * Coupon emission price special account Id (optional)
   */
  customers_price_special_account_id?: string | null;
  /**
   * Coupon emission price special account title (optional)
   */
  customers_price_special_account_title?: string | null;
  /**
   * Coupon emission price type (optional)
   */
  customers_price_type?: CouponBatch.CustomersPriceTypeEnum | null;
  /**
   * Unique id of coupon batch
   */
  id?: string | null;
  links?: Array<CouponBatchLink>;
  /**
   * Loyalty emission id
   */
  loyalty_id?: string | null;
  /**
   * Coupons batch public description (optional)
   */
  public_description?: string | null;
  /**
   * Coupons batch public title (optional)
   */
  public_title?: string | null;
  /**
   * Recommendation fee amount (optional)
   */
  recommendation_fee_amount?: number | null;
  /**
   * Recommendation fee bonuses currence ISO code (optional)
   */
  recommendation_fee_bonuses_currency?: string | null;
  /**
   * Recommendation fee Batch CODE (optional)
   */
  recommendation_fee_coupon_batch_code?: string | null;
  /**
   * Recommendation fee Batch Id (optional)
   */
  recommendation_fee_coupon_batch_id?: string | null;
  /**
   * Recommendation fee Batch TITLE (optional)
   */
  recommendation_fee_coupon_batch_title?: string | null;
  /**
   * Value of recommendation fee promotional bonuses expire date (optional)
   */
  recommendation_fee_promotional_bonuses_expire_date?: string | null;
  /**
   * Recommendation fee expiration value type (optional)
   */
  recommendation_fee_promotional_bonuses_expire_type?: CouponBatch.RecommendationFeePromotionalBonusesExpireTypeEnum | null;
  /**
   * Value of recommendation fee promotional bonuses expire (optional)
   */
  recommendation_fee_promotional_bonuses_expire_value?: number | null;
  /**
   * Recommendation fee special account Id (optional)
   */
  recommendation_fee_special_account_id?: string | null;
  /**
   * Recommendation fee special account title (optional)
   */
  recommendation_fee_special_account_title?: string | null;
  /**
   * Recommendation fee type (optional)
   */
  recommendation_fee_type?: CouponBatch.RecommendationFeeTypeEnum | null;
  /**
   * Redeem only at terminal restriction (optional)
   */
  redeem_at_terminal?: number | null;
  /**
   * Coupons share allowed for customer (optional)
   */
  share_allowed?: number | null;
  /**
   * Type of coupon
   */
  type?: CouponBatch.TypeEnum | null;
}
export namespace CouponBatch {
  export type CustomersPriceTypeEnum = 'bonuses' | 'special-account';
  export const CustomersPriceTypeEnum = {
    Bonuses: 'bonuses' as CustomersPriceTypeEnum,
    SpecialAccount: 'special-account' as CustomersPriceTypeEnum
  };
  export type RecommendationFeePromotionalBonusesExpireTypeEnum =
    | 'date'
    | 'day'
    | 'month';
  export const RecommendationFeePromotionalBonusesExpireTypeEnum = {
    Date: 'date' as RecommendationFeePromotionalBonusesExpireTypeEnum,
    Day: 'day' as RecommendationFeePromotionalBonusesExpireTypeEnum,
    Month: 'month' as RecommendationFeePromotionalBonusesExpireTypeEnum
  };
  export type RecommendationFeeTypeEnum =
    | 'none'
    | 'regular-bonuses'
    | 'promotional-bonuses'
    | 'special-account'
    | 'coupon';
  export const RecommendationFeeTypeEnum = {
    None: 'none' as RecommendationFeeTypeEnum,
    RegularBonuses: 'regular-bonuses' as RecommendationFeeTypeEnum,
    PromotionalBonuses: 'promotional-bonuses' as RecommendationFeeTypeEnum,
    SpecialAccount: 'special-account' as RecommendationFeeTypeEnum,
    Coupon: 'coupon' as RecommendationFeeTypeEnum
  };
  export type TypeEnum = 'check-modifier' | 'certificate' | 'external';
  export const TypeEnum = {
    CheckModifier: 'check-modifier' as TypeEnum,
    Certificate: 'certificate' as TypeEnum,
    External: 'external' as TypeEnum
  };
}
