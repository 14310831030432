/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TransactionReceiptActions {
  /**
   * Customer\'s receipt print on terminal (optional)
   */
  print_on_terminal?: boolean | null;
  send_to_customer?: Array<string>;
}
