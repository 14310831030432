/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RuleCalculationBaseData {
  /**
   * Check amount
   */
  amount?: number | null;
  /**
   * Check bonus points redeem amount
   */
  bonuses_redeemed_amount?: number | null;
}
