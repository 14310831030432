/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CorporateCustomerCardInfo } from './corporateCustomerCardInfo';
import { RouteOperationDetails } from './routeOperationDetails';
import { CardInfo } from './cardInfo';
import { Goods } from './goods';

export interface RouteOperation {
  /**
   * Operation \'assistance\' available (readonly)
   */
  assistance_available?: boolean;
  /**
   * Assistance trouble type (only if status is \'customer-assistance-needed\')
   */
  assistance_type?: RouteOperation.AssistanceTypeEnum | null;
  /**
   * Operation \'canceled\' available (readonly)
   */
  canceled_available?: boolean;
  /**
   * Comment
   */
  comment?: string | null;
  corporate_customer?: CorporateCustomerCardInfo;
  /**
   * Corporate Customer Id
   */
  corporate_customer_id?: string | null;
  customer?: CardInfo;
  /**
   * Retail Customer Id
   */
  customer_id?: string | null;
  /**
   * Customer type
   */
  customer_type?: RouteOperation.CustomerTypeEnum | null;
  /**
   * Route date
   */
  date?: string | null;
  details?: RouteOperationDetails;
  /**
   * Operation \'failed\' available (readonly)
   */
  failed_available?: boolean;
  /**
   * List of goods (optional)
   */
  goods?: Array<Goods> | null;
  /**
   * Operation Id
   */
  id?: string | null;
  /**
   * Preliminary Transaction Id (optional, only for pending type)
   */
  preliminary_transaction_id?: string | null;
  /**
   * Operation \'processed\' available (readonly)
   */
  processed_available?: boolean;
  /**
   * Operation \'refueling\' available (readonly)
   */
  refueling_available?: boolean;
  /**
   * Scheduled time in HH:mm / HH:mm:ss format
   */
  scheduled?: string | null;
  /**
   * Operation \'shipping\' available (readonly)
   */
  shipping_available?: boolean;
  /**
   * Operation status
   */
  status?: RouteOperation.StatusEnum | null;
  /**
   * Transaction Id (Finalized transaction Id)
   */
  transaction_id?: string | null;
  /**
   * Operation type
   */
  type?: RouteOperation.TypeEnum | null;
}
export namespace RouteOperation {
  export type AssistanceTypeEnum = 'fuel-cap-locked' | 'vehicle-not-found';
  export const AssistanceTypeEnum = {
    FuelCapLocked: 'fuel-cap-locked' as AssistanceTypeEnum,
    VehicleNotFound: 'vehicle-not-found' as AssistanceTypeEnum
  };
  export type CustomerTypeEnum = 'customer' | 'corporate-customer' | 'business';
  export const CustomerTypeEnum = {
    Customer: 'customer' as CustomerTypeEnum,
    CorporateCustomer: 'corporate-customer' as CustomerTypeEnum,
    Business: 'business' as CustomerTypeEnum
  };
  export type StatusEnum =
    | 'pending'
    | 'processed'
    | 'failed'
    | 'canceled'
    | 'shipping'
    | 'customer-assistance-needed'
    | 'refueling';
  export const StatusEnum = {
    Pending: 'pending' as StatusEnum,
    Processed: 'processed' as StatusEnum,
    Failed: 'failed' as StatusEnum,
    Canceled: 'canceled' as StatusEnum,
    Shipping: 'shipping' as StatusEnum,
    CustomerAssistanceNeeded: 'customer-assistance-needed' as StatusEnum,
    Refueling: 'refueling' as StatusEnum
  };
  export type TypeEnum =
    | 'sale-fuel-to-tank'
    | 'fuel-taxi-order-delivery'
    | 'online-store-order-delivery';
  export const TypeEnum = {
    SaleFuelToTank: 'sale-fuel-to-tank' as TypeEnum,
    FuelTaxiOrderDelivery: 'fuel-taxi-order-delivery' as TypeEnum,
    OnlineStoreOrderDelivery: 'online-store-order-delivery' as TypeEnum
  };
}
