/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CardCategoryExtendedSettings } from './cardCategoryExtendedSettings';

/**
 * Card category
 */
export interface CardCategory {
  extended_settings?: CardCategoryExtendedSettings;
  /**
   * Card category ID
   */
  id: string;
  /**
   * URL of batch image
   */
  image?: string | null;
  /**
   * Show category to customers
   */
  show_to_customers?: number | null;
  /**
   * Card category title
   */
  title?: string | null;
  /**
   * Category type (readonly)
   */
  type?: CardCategory.TypeEnum | null;
  /**
   * Category weight
   */
  weight?: number | null;
}
export namespace CardCategory {
  export type TypeEnum = 'base' | 'custom';
  export const TypeEnum = {
    Base: 'base' as TypeEnum,
    Custom: 'custom' as TypeEnum
  };
}
