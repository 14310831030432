/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ProtectionOptions {
  /**
   * Protected action marker
   */
  operation?: ProtectionOptions.OperationEnum;
  /**
   * Protection parameters (depends on protection type)
   */
  params?: object;
  /**
   * Protection type (\'none\' - without protection)
   */
  protection?: ProtectionOptions.ProtectionEnum;
}
export namespace ProtectionOptions {
  export type OperationEnum = 'terminal_remind_password';
  export const OperationEnum = {
    TerminalRemindPassword: 'terminal_remind_password' as OperationEnum
  };
  export type ProtectionEnum = 'none' | 'recaptcha';
  export const ProtectionEnum = {
    None: 'none' as ProtectionEnum,
    Recaptcha: 'recaptcha' as ProtectionEnum
  };
}
