import { Route } from '@angular/router';

export const stewardRoutes: Route[] = [
  // {
  //   path: 'auth',
  //   loadComponent: () =>
  //     import('./pages/auth/auth.page').then((m) => m.AuthPage)
  // },
  {
    path: '',
    loadComponent: () =>
      import('./steward-home/steward-home.page').then((m) => m.StewardHomePage)
  }
  // {
  //   path: 'services',
  //   component: StewardServicesPage,
  // }
  // ,
  // {
  //   path: 'history',
  //   component: StewardHistoryPage,
  // }
  // , {
  //   path: 'statics',
  //   component: StewardStaticsPage,
  // }
  // , {
  //   path: 'prepaid',
  //   component: StewardPrepaidPage
  // },
  // {
  //   path: 'customer',
  //   component: StewardCustomerPage
  // },
  // {
  //   path: 'redeem',
  //   component: StewardRedeemPage
  // }
];
