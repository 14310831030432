/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type PriceExplainValueType =
  | 'price'
  | 'base-tax'
  | 'base-taxes'
  | 'sales-tax'
  | 'sales-taxes'
  | 'margin'
  | 'margins'
  | 'option'
  | 'options';

export const PriceExplainValueType = {
  Price: 'price' as PriceExplainValueType,
  BaseTax: 'base-tax' as PriceExplainValueType,
  BaseTaxes: 'base-taxes' as PriceExplainValueType,
  SalesTax: 'sales-tax' as PriceExplainValueType,
  SalesTaxes: 'sales-taxes' as PriceExplainValueType,
  Margin: 'margin' as PriceExplainValueType,
  Margins: 'margins' as PriceExplainValueType,
  Option: 'option' as PriceExplainValueType,
  Options: 'options' as PriceExplainValueType
};
