/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ThresholdsValues } from './thresholdsValues';

export interface DeliveryType {
  /**
   * Additional customer info
   */
  additional_customer_info?: string;
  /**
   * Delivery address required
   */
  delivery_address_required?: DeliveryType.DeliveryAddressRequiredEnum;
  /**
   * Delivery date required
   */
  delivery_date_required?: number;
  /**
   * Delivery time required
   */
  delivery_time_required?: number;
  /**
   * Delivery type ID
   */
  id?: string;
  /**
   * Price values depends of thresholds
   */
  price?: Array<ThresholdsValues>;
  /**
   * Title
   */
  title?: string;
}
export namespace DeliveryType {
  export type DeliveryAddressRequiredEnum = 'none' | 'pos' | 'customer';
  export const DeliveryAddressRequiredEnum = {
    None: 'none' as DeliveryAddressRequiredEnum,
    Pos: 'pos' as DeliveryAddressRequiredEnum,
    Customer: 'customer' as DeliveryAddressRequiredEnum
  };
}
