/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TerminalVehicleFuelSettings {
  /**
   * Odometer measuremen units selection type
   */
  odometer_measurement_units?: TerminalVehicleFuelSettings.OdometerMeasurementUnitsEnum | null;
}
export namespace TerminalVehicleFuelSettings {
  export type OdometerMeasurementUnitsEnum =
    | 'kilometer'
    | 'mile'
    | 'hour'
    | 'selected-by-customer'
    | 'set-by-volume-unit';
  export const OdometerMeasurementUnitsEnum = {
    Kilometer: 'kilometer' as OdometerMeasurementUnitsEnum,
    Mile: 'mile' as OdometerMeasurementUnitsEnum,
    Hour: 'hour' as OdometerMeasurementUnitsEnum,
    SelectedByCustomer: 'selected-by-customer' as OdometerMeasurementUnitsEnum,
    SetByVolumeUnit: 'set-by-volume-unit' as OdometerMeasurementUnitsEnum
  };
}
