/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type IdType =
  | 'phone'
  | 'qr'
  | 'email'
  | 'card'
  | 'temporary-card'
  | 'id'
  | 'external-id'
  | 'social-network'
  | 'ssn'
  | 'itin'
  | 'itn';

export const IdType = {
  Phone: 'phone' as IdType,
  Qr: 'qr' as IdType,
  Email: 'email' as IdType,
  Card: 'card' as IdType,
  TemporaryCard: 'temporary-card' as IdType,
  Id: 'id' as IdType,
  ExternalId: 'external-id' as IdType,
  SocialNetwork: 'social-network' as IdType,
  Ssn: 'ssn' as IdType,
  Itin: 'itin' as IdType,
  Itn: 'itn' as IdType
};
