/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountLimits } from './accountLimits';
import { SpecialAccount } from './specialAccount';

export interface CustomerSpecialAccount {
  /**
   * On hand amount
   */
  amount?: number | null;
  /**
   * Amount of funds on the account available for using (null if unlimited)
   */
  available_amount?: number | null;
  /**
   * balance
   */
  balance?: number | null;
  /**
   * Credit limit
   */
  credit_limit?: number | null;
  /**
   * Credit_type
   */
  credit_type?: CustomerSpecialAccount.CreditTypeEnum | null;
  /**
   * Customer special account ID
   */
  customer_special_account_id?: string | null;
  /**
   * Account extended flag
   */
  extended?: number | null;
  /**
   * Account applicable goods filter (optional, only for extended accounts)
   */
  goods_items?: Array<string> | null;
  /**
   * Special account ID
   */
  id?: string | null;
  limits?: AccountLimits;
  /**
   * Odometer value needed flag (optional, only for extended accounts)
   */
  odometer?: CustomerSpecialAccount.OdometerEnum | null;
  /**
   * Write-off from account amout (optional)
   */
  redeem_amount?: number | null;
  /**
   * Total refill account amount (optional)
   */
  refill_amount?: number | null;
  /**
   * Additional PIN-code for access to the account exists (optional, only for extended accounts)
   */
  security_code?: number | null;
  special_account?: SpecialAccount;
  /**
   * Special account title
   */
  title?: string | null;
}
export namespace CustomerSpecialAccount {
  export type CreditTypeEnum = 'debit' | 'credit' | 'credit-unlimited';
  export const CreditTypeEnum = {
    Debit: 'debit' as CreditTypeEnum,
    Credit: 'credit' as CreditTypeEnum,
    CreditUnlimited: 'credit-unlimited' as CreditTypeEnum
  };
  export type OdometerEnum = 'no' | 'yes' | 'possible';
  export const OdometerEnum = {
    No: 'no' as OdometerEnum,
    Yes: 'yes' as OdometerEnum,
    Possible: 'possible' as OdometerEnum
  };
}
