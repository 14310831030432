/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Service type
 */
export interface IncustControllersTermApiGoodsServiceTypes200ResponseInner {
  /**
   * type description
   */
  description?: string;
  /**
   * type identifier
   */
  type?: string;
}
