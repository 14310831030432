/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coupon } from './coupon';
import { RuleImplementationResultData } from './ruleImplementationResultData';
import { LoyaltyRuleDependsTimeZone } from './loyaltyRuleDependsTimeZone';
import { LoyaltyRuleDependsType } from './loyaltyRuleDependsType';
import { LoyaltyRuleDependsCalcPeriod } from './loyaltyRuleDependsCalcPeriod';

export interface RuleImplementationData {
  calculated_result?: RuleImplementationResultData;
  coupon?: Coupon;
  dependencies_time_zone?: LoyaltyRuleDependsTimeZone;
  dependencies_time_zone_value?: string | null;
  /**
   * Amount/value, according to the rule dependency type, on which the rule was triggered (optional)
   */
  depends_amount?: number | null;
  depends_calc_period?: LoyaltyRuleDependsCalcPeriod;
  depends_type?: LoyaltyRuleDependsType;
  /**
   * Customer category ID, according to the rule dependency type, on which the rule was triggered (optional)
   */
  depends_user_category_id?: string | null;
  /**
   * Customer category valid from date and time, according to the rule dependency type, on which the rule was triggered (optional)
   */
  depends_user_category_valid_from?: string | null;
  /**
   * Implemented by
   */
  implemented_by?: RuleImplementationData.ImplementedByEnum | null;
  /**
   * Rules calculation and implementation type
   */
  implemented_calculation_type?: RuleImplementationData.ImplementedCalculationTypeEnum | null;
  result?: RuleImplementationResultData;
}
export namespace RuleImplementationData {
  export type ImplementedByEnum =
    | 'automatic-rule'
    | 'manual-rule'
    | 'display-rule'
    | 'coupon';
  export const ImplementedByEnum = {
    AutomaticRule: 'automatic-rule' as ImplementedByEnum,
    ManualRule: 'manual-rule' as ImplementedByEnum,
    DisplayRule: 'display-rule' as ImplementedByEnum,
    Coupon: 'coupon' as ImplementedByEnum
  };
  export type ImplementedCalculationTypeEnum =
    | 'summarizing'
    | 'maximum'
    | 'sequential';
  export const ImplementedCalculationTypeEnum = {
    Summarizing: 'summarizing' as ImplementedCalculationTypeEnum,
    Maximum: 'maximum' as ImplementedCalculationTypeEnum,
    Sequential: 'sequential' as ImplementedCalculationTypeEnum
  };
}
