<ion-content class="ion-padding">
  <div class="card-holder">
    <ion-card>
      <div class="logo-holder">
        <img src="logo-auth.png" alt="logo" />
      </div>
      <h2 class="ion-text-center ion-margin-bottom">Вітаємо у InCust term!</h2>
      <div [formGroup]="authForm" class="input-group">
        <div>
          <ion-label position="stacked">Login</ion-label>
          <ion-input
            aria-label="Custom input"
            class="custom-input"
            placeholder="Login"
            fill="outline"
            formControlName="login"
          >
          </ion-input>
        </div>

        <div>
          <ion-label position="stacked">Password</ion-label>
          <ion-input
            type="password"
            aria-label="Custom input"
            class="custom-input"
            placeholder="Password"
            fill="outline"
            formControlName="password"
          >
            <ion-input-password-toggle slot="end"></ion-input-password-toggle>
          </ion-input>
        </div>
      </div>

      <ion-button expand="block" color="secondary" (click)="signIn()"
        >Увійти</ion-button
      >
    </ion-card>
  </div>
</ion-content>
