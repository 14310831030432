/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankCardExternalInfo } from './bankCardExternalInfo';
import { ExternalBankCardsEDCTypes } from './externalBankCardsEDCTypes';

export interface PaymentProcess {
  bank_card_external?: BankCardExternalInfo;
  edc_card_type?: ExternalBankCardsEDCTypes;
  /**
   * Payment processing failed flag
   */
  failed?: boolean;
  /**
   * Payment Id
   */
  id: string;
  /**
   * Payment operation type (depends on payment_system_type)
   */
  operation_type: PaymentProcess.OperationTypeEnum;
  /**
   * Payment system type (depends on payment type)
   */
  payment_system_type: PaymentProcess.PaymentSystemTypeEnum;
  /**
   * Payment processing pre-request (depends on payment_system_type)
   */
  processing_prerequest?: object | null;
  /**
   * Payment processing request (depends on payment_system_type)
   */
  processing_request?: object | null;
  /**
   * Payment processing result (depends on payment_system_type)
   */
  processing_result?: object | null;
}
export namespace PaymentProcess {
  export type OperationTypeEnum =
    | 'purchase'
    | 'authorize'
    | 'completion'
    | 'refund'
    | 'cancel';
  export const OperationTypeEnum = {
    Purchase: 'purchase' as OperationTypeEnum,
    Authorize: 'authorize' as OperationTypeEnum,
    Completion: 'completion' as OperationTypeEnum,
    Refund: 'refund' as OperationTypeEnum,
    Cancel: 'cancel' as OperationTypeEnum
  };
  export type PaymentSystemTypeEnum = 'amp' | 'pax';
  export const PaymentSystemTypeEnum = {
    Amp: 'amp' as PaymentSystemTypeEnum,
    Pax: 'pax' as PaymentSystemTypeEnum
  };
}
