/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankCardExternalInfo } from './bankCardExternalInfo';
import { MobileAppInfo } from './mobileAppInfo';
import { PaymentExternalInfo } from './paymentExternalInfo';

export interface Payment {
  /**
   * To pay amount
   */
  amount: number | null;
  bank_card_external?: BankCardExternalInfo;
  /**
   * Payment change (optional, only for \'cash\' type)
   */
  change?: number;
  /**
   * Currensy ISO code (depends on transaction type)
   */
  currency?: string | null;
  /**
   * Customer fee amount (optional, only for \'mobile-app\' type)
   */
  customer_fee_amount?: number | null;
  external_info?: PaymentExternalInfo;
  /**
   * Payment Id
   */
  id?: string | null;
  mobileapp?: MobileAppInfo;
  /**
   * Payment receipt (optional, only for \'cash\' type)
   */
  receipt?: number;
  /**
   * Operation status
   */
  status?: Payment.StatusEnum | null;
  /**
   * Payment type
   */
  type: Payment.TypeEnum | null;
}
export namespace Payment {
  export type StatusEnum =
    | 'inprocess'
    | 'success'
    | 'fail'
    | 'canceled'
    | 'error'
    | 'authorized';
  export const StatusEnum = {
    Inprocess: 'inprocess' as StatusEnum,
    Success: 'success' as StatusEnum,
    Fail: 'fail' as StatusEnum,
    Canceled: 'canceled' as StatusEnum,
    Error: 'error' as StatusEnum,
    Authorized: 'authorized' as StatusEnum
  };
  export type TypeEnum =
    | 'cash'
    | 'bank-card'
    | 'bank-card-external'
    | 'mobile-app'
    | 'special-account';
  export const TypeEnum = {
    Cash: 'cash' as TypeEnum,
    BankCard: 'bank-card' as TypeEnum,
    BankCardExternal: 'bank-card-external' as TypeEnum,
    MobileApp: 'mobile-app' as TypeEnum,
    SpecialAccount: 'special-account' as TypeEnum
  };
}
