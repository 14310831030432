/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionErrors } from './transactionErrors';
import { JobDetails } from './jobDetails';

export interface Job {
  /**
   * Automaticaly capture funds of authorized payment transactions when job finished
   */
  auto_capture_parent_transaction?: boolean;
  /**
   * Automaticaly start the job after creation
   */
  auto_start?: boolean;
  /**
   * Corporate User ID (optional)
   */
  corporate_user_id?: string;
  /**
   * Created time
   */
  created?: string;
  details?: JobDetails;
  /**
   * Capturing transaction errors list
   */
  finalize_transaction_errors?: Array<TransactionErrors>;
  /**
   * Flag of finalize transaction failed. Exists and set to TRUE if capture funds of authorized payment failed
   */
  finalize_transaction_failed?: boolean;
  /**
   * Сapture funds of authorized payment or cancellation transaction ID (optional)
   */
  finalize_transaction_id?: string;
  /**
   * Finished time
   */
  finished?: string;
  /**
   * Job ID
   */
  id?: string;
  /**
   * Started time
   */
  started?: string;
  /**
   * Job status
   */
  status?: Job.StatusEnum;
  /**
   * Linked transaction ID (optional)
   */
  transaction_id?: string;
  /**
   * Job type (required for Job creation)
   */
  type?: Job.TypeEnum;
  /**
   * User ID (optional)
   */
  user_id?: string;
}
export namespace Job {
  export type StatusEnum =
    | 'created'
    | 'starting'
    | 'inprocess'
    | 'stopping'
    | 'finished';
  export const StatusEnum = {
    Created: 'created' as StatusEnum,
    Starting: 'starting' as StatusEnum,
    Inprocess: 'inprocess' as StatusEnum,
    Stopping: 'stopping' as StatusEnum,
    Finished: 'finished' as StatusEnum
  };
  export type TypeEnum = 'fuel' | 'equipment';
  export const TypeEnum = {
    Fuel: 'fuel' as TypeEnum,
    Equipment: 'equipment' as TypeEnum
  };
}
