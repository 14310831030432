/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CouponBatch } from './couponBatch';

export interface Coupon {
  /**
   * Coupon check applicable for check (optional, check only)
   */
  applicable?: boolean | null;
  batch?: CouponBatch;
  /**
   * Business code
   */
  business_code?: string | null;
  /**
   * Coupon certificate type refill amount (optional)
   */
  charge_amount?: number | null;
  /**
   * currency (optional)
   */
  charge_bonuses_currency?: string | null;
  /**
   * Value of promotional bonuses expire date (optional)
   */
  charge_promotional_bonuses_expire_date?: string | null;
  /**
   * Promotional bonuses expiration value type (optional)
   */
  charge_promotional_bonuses_expire_type?: Coupon.ChargePromotionalBonusesExpireTypeEnum | null;
  /**
   * Value of promotional bonuses expire (optional)
   */
  charge_promotional_bonuses_expire_value?: number | null;
  /**
   * Special account currency (optional)
   */
  charge_special_account_currency?: string | null;
  /**
   * Special account ID (optional)
   */
  charge_special_account_id?: string | null;
  /**
   * Special account title (optional)
   */
  charge_special_account_title?: string | null;
  /**
   * Coupon certificate type refill object (optional)
   */
  charge_type?: Coupon.ChargeTypeEnum | null;
  /**
   * Coupon code
   */
  code?: string | null;
  /**
   * Coupon Description
   */
  description?: string | null;
  /**
   * Do not add emitted coupons to the customer\'s wallet (optional, check only)
   */
  do_not_add_to_wallet?: boolean | null;
  /**
   * Coupon check implementation error (optional, check only)
   */
  error?: string | null;
  /**
   * Coupon external_code (optional)
   */
  external_code?: string | null;
  /**
   * External code visibility type (optional)
   */
  external_code_visibility_type?: Coupon.ExternalCodeVisibilityTypeEnum | null;
  /**
   * Gift Card ID (optional)
   */
  gift_card_id?: string | null;
  /**
   * Unique id of coupon
   */
  id?: string | null;
  image?: string | null;
  /**
   * Coupon locked by transaction process (optional)
   */
  locked?: boolean | null;
  /**
   * Loyalty emission id
   */
  loyalty_id?: string | null;
  /**
   * Coupon processing status (optional, check by transaction only)
   */
  processing_status?: Coupon.ProcessingStatusEnum | null;
  /**
   * Redeem only at terminal restriction
   */
  redeem_at_terminal?: number | null;
  /**
   * Coupon redeemed transaction Id (optional)
   */
  redeemed_transaction_id?: string | null;
  /**
   * Coupons share allowed for customer
   */
  share_allowed?: number | null;
  /**
   * Status of coupon
   */
  status?: Coupon.StatusEnum | null;
  /**
   * Coupon Title
   */
  title?: string | null;
  /**
   * Type of coupon
   */
  type?: Coupon.TypeEnum | null;
  /**
   * Coupon valid flag (optional)
   */
  valid?: boolean | null;
}
export namespace Coupon {
  export type ChargePromotionalBonusesExpireTypeEnum = 'date' | 'day' | 'month';
  export const ChargePromotionalBonusesExpireTypeEnum = {
    Date: 'date' as ChargePromotionalBonusesExpireTypeEnum,
    Day: 'day' as ChargePromotionalBonusesExpireTypeEnum,
    Month: 'month' as ChargePromotionalBonusesExpireTypeEnum
  };
  export type ChargeTypeEnum =
    | 'regular-bonuses'
    | 'promotional-bonuses'
    | 'special-account';
  export const ChargeTypeEnum = {
    RegularBonuses: 'regular-bonuses' as ChargeTypeEnum,
    PromotionalBonuses: 'promotional-bonuses' as ChargeTypeEnum,
    SpecialAccount: 'special-account' as ChargeTypeEnum
  };
  export type ExternalCodeVisibilityTypeEnum = 'always-open' | 'on-redeem';
  export const ExternalCodeVisibilityTypeEnum = {
    AlwaysOpen: 'always-open' as ExternalCodeVisibilityTypeEnum,
    OnRedeem: 'on-redeem' as ExternalCodeVisibilityTypeEnum
  };
  export type ProcessingStatusEnum = 'pending' | 'processed' | 'error';
  export const ProcessingStatusEnum = {
    Pending: 'pending' as ProcessingStatusEnum,
    Processed: 'processed' as ProcessingStatusEnum,
    Error: 'error' as ProcessingStatusEnum
  };
  export type StatusEnum = 'open' | 'redeemed' | 'cancelled';
  export const StatusEnum = {
    Open: 'open' as StatusEnum,
    Redeemed: 'redeemed' as StatusEnum,
    Cancelled: 'cancelled' as StatusEnum
  };
  export type TypeEnum = 'check-modifier' | 'certificate' | 'external';
  export const TypeEnum = {
    CheckModifier: 'check-modifier' as TypeEnum,
    Certificate: 'certificate' as TypeEnum,
    External: 'external' as TypeEnum
  };
}
