/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TerminalExternalAmpDevice {
  /**
   * Device connection type
   */
  connection_type?: TerminalExternalAmpDevice.ConnectionTypeEnum;
  /**
   * Device settings (depends on device type)
   */
  settings?: object;
  /**
   * Device type
   */
  type?: TerminalExternalAmpDevice.TypeEnum;
  /**
   * Device vendor specific type
   */
  vendor_type?: TerminalExternalAmpDevice.VendorTypeEnum;
}
export namespace TerminalExternalAmpDevice {
  export type ConnectionTypeEnum = 'local';
  export const ConnectionTypeEnum = {
    Local: 'local' as ConnectionTypeEnum
  };
  export type TypeEnum = 'payment-terminal';
  export const TypeEnum = {
    PaymentTerminal: 'payment-terminal' as TypeEnum
  };
  export type VendorTypeEnum = 'amp-6500';
  export const VendorTypeEnum = {
    Amp6500: 'amp-6500' as VendorTypeEnum
  };
}
