/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface IncustControllersTermApiTransactionCancelRequest {
  /**
   * Comment
   */
  comment?: string;
  /**
   * Temporary code used in canceled transaction
   */
  temporary_code?: string;
  /**
   * Canceled transaction ID
   */
  transaction_id?: string;
}
