/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TerminalScreensaverSource {
  /**
   * Source order (non-negative)
   */
  order?: number;
  /**
   * Show time (null or greater than zero)
   */
  show_time?: number | null;
  /**
   * Source type
   */
  type?: TerminalScreensaverSource.TypeEnum;
  /**
   * Source url
   */
  url?: string | null;
}
export namespace TerminalScreensaverSource {
  export type TypeEnum = 'youtube' | 'image';
  export const TypeEnum = {
    Youtube: 'youtube' as TypeEnum,
    Image: 'image' as TypeEnum
  };
}
