/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TourCardServiceUse {
  /**
   * Tourist card ID
   */
  card_id: string;
  /**
   * Tourist card service ID
   */
  service_id: string;
}
