import { Route } from '@angular/router';
import { AuthGuard } from '@incust/auth';
import { ModeGuard } from './guards/mode.guard';
import { stewardRoutes } from './pages/steward/steward.routes';

export const routes: Route[] = [
  {
    path: 'auth',
    loadComponent: () =>
      import('./pages/auth/auth.page').then((m) => m.AuthPage)
  },
  {
    path: 'staff',
    loadComponent: () =>
      import('./pages/auth-staff/auth-staff.page').then((m) => m.AuthStaffPage)
  },
  {
    path: 'pos-mode',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/pos-mode/pos-mode.page').then((m) => m.PosModePage)
      },
      {
        path: 'auto-choice',
        loadComponent: () =>
          import('./pages/auto-choice/auto-choice.page').then(
            (m) => m.AutoChoicePage
          )
      }
    ],
    canActivate: [AuthGuard, ModeGuard]
  },
  {
    path: 'steward',
    children: stewardRoutes
  },
  {
    path: 'main',
    redirectTo: 'pos-mode'
  },
  {
    path: 'transactions',
    redirectTo: 'pos-mode'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'pos-mode'
  }
];
