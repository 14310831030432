/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FuelControllerGpsData {
  /**
   * Controller ID
   */
  controller_id?: string | null;
  /**
   * Heading value in degrees
   */
  heading?: number | null;
  /**
   * Latitude in decimal degrees
   */
  latitude?: number | null;
  /**
   * GPS data live (from GPS module on controller) flag
   */
  live?: boolean | null;
  /**
   * Longitude in decimal degrees
   */
  longitude?: number | null;
  /**
   * GPS data time (UTC)
   */
  processed?: string | null;
  /**
   * Speed over ground in kilometers per hour
   */
  speed?: number | null;
  /**
   * Flag of validity data
   */
  valid?: number | null;
}
