/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerAccessType } from './customerAccessType';
import { CardCategory } from './cardCategory';
import { UserStatus } from './userStatus';

/**
 * Customer data
 */
export interface CustomerInfo {
  access_type?: CustomerAccessType;
  /**
   * User avatar url
   */
  avatar?: string;
  card_category?: CardCategory;
  /**
   * Created customer date and time
   */
  created?: string;
  /**
   * User has mobileapp tokens (use mobileapp)
   */
  have_mobileapp_token?: boolean;
  /**
   * Customer ID
   */
  id?: string;
  /**
   * Customer last sale date
   */
  last_sale_date?: string;
  /**
   * User is new for loyalty
   */
  new?: number;
  /**
   * Show questionnaire flag
   */
  show_questionnaire?: number;
  /**
   * Customer user birthdate
   */
  user_birth_date?: string;
  /**
   * User consent exists flag
   */
  user_consent_exist?: boolean;
  /**
   * Created user date and time
   */
  user_created?: string;
  user_email?: string;
  user_external_id?: string;
  user_gender?: CustomerInfo.UserGenderEnum;
  /**
   * Public Customer user Name
   */
  user_name?: string;
  user_phone?: string;
  user_status?: UserStatus;
}
export namespace CustomerInfo {
  export type UserGenderEnum = 'male' | 'female' | 'other';
  export const UserGenderEnum = {
    Male: 'male' as UserGenderEnum,
    Female: 'female' as UserGenderEnum,
    Other: 'other' as UserGenderEnum
  };
}
