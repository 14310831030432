import { Component, inject, OnInit } from '@angular/core';
import { AuthHolderService } from '@incust/auth';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { Router } from '@angular/router';
import { SettingsService } from './services/settings.service';

@Component({
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  private authService = inject(AuthHolderService);
  private settingsService = inject(SettingsService);
  private router = inject(Router);

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.authService.syncSettings();
    }

    this.settingsService.init();
  }
}
