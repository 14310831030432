/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BillingInfo {
  /**
   * Address (depends on the payment system)
   */
  address?: object;
  /**
   * Email
   */
  email?: string;
  /**
   * Name
   */
  name?: string;
  /**
   * Phone number
   */
  phone?: string;
}
