/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PriceTaxes } from './priceTaxes';
import { PriceMargins } from './priceMargins';
import { OdometerUnitType } from './odometerUnitType';

export interface JobEquipment {
  /**
   * Authorized item amount
   */
  authorized_amount?: number | null;
  /**
   * Authorized item price
   */
  authorized_price?: number | null;
  /**
   * Authorized price currency ISO code (Only if filling_price_type is \'currency\')
   */
  authorized_price_currency?: string | null;
  authorized_price_margins?: PriceMargins;
  /**
   * Authorized price special account Id (Only if filling_price_type is \'special-account\')
   */
  authorized_price_special_account_id?: string | null;
  authorized_price_taxes?: PriceTaxes;
  /**
   * Authorized price type
   */
  authorized_price_type?: JobEquipment.AuthorizedPriceTypeEnum | null;
  /**
   * Authorized price unit - quantity or currency ISO code (Only if filling_price_type is \'special-account\')
   */
  authorized_price_unit?: string | null;
  /**
   * Authorized item quantity
   */
  authorized_quantity?: number | null;
  /**
   * AVI (automatic vehicle identification) Id value (optional)
   */
  avi_id?: string | null;
  /**
   * Item amount captured by check
   */
  captured_amount?: number | null;
  /**
   * Item price captured by check
   */
  captured_price?: number | null;
  /**
   * Item quantity captured by check
   */
  captured_quantity?: number | null;
  /**
   * Charged item amount
   */
  charged_amount?: number | null;
  /**
   * Charged item price
   */
  charged_price?: number | null;
  /**
   * Charged item quantity
   */
  charged_quantity?: number | null;
  /**
   * Charging item amount
   */
  charging_amount?: number | null;
  /**
   * Charging item price
   */
  charging_price?: number | null;
  /**
   * Charging item quantity
   */
  charging_quantity?: number | null;
  /**
   * Controller connection failed flag (Exists and set to true only if connection with controller failed)
   */
  connection_failed?: boolean | null;
  /**
   * Controller Id
   */
  controller_id?: string | null;
  /**
   * Controller type
   */
  controller_type?: JobEquipment.ControllerTypeEnum | null;
  /**
   * Equipment Id
   */
  equipment_id: string | null;
  /**
   * Controller input number
   */
  equipment_input_number?: number | null;
  /**
   * Ability to monitor the working condition flag
   */
  equipment_monitor_working_status?: number | null;
  /**
   * Controller output number
   */
  equipment_output_number?: number | null;
  /**
   * Equipment type
   */
  equipment_type?: JobEquipment.EquipmentTypeEnum | null;
  /**
   * Error message (Exists only if controller send error message)
   */
  error_message?: string | null;
  /**
   * Goods code
   */
  goods_code?: string | null;
  /**
   * Item amount
   */
  item_amount?: number | null;
  /**
   * Item price
   */
  item_price?: number | null;
  /**
   * Item quantity
   */
  item_quantity?: number | null;
  /**
   * Odometer/engine hours value, up to two decimal digits (optional)
   */
  odometer?: number | null;
  odometer_unit?: OdometerUnitType;
  /**
   * Equipment charging status
   */
  status?: JobEquipment.StatusEnum | null;
  /**
   * Qr Code / Tag of vehicle or equipment value (optional)
   */
  vehicle_id?: string | null;
}
export namespace JobEquipment {
  export type AuthorizedPriceTypeEnum = 'special-account' | 'currency';
  export const AuthorizedPriceTypeEnum = {
    SpecialAccount: 'special-account' as AuthorizedPriceTypeEnum,
    Currency: 'currency' as AuthorizedPriceTypeEnum
  };
  export type ControllerTypeEnum = 'plc-modicon-m241';
  export const ControllerTypeEnum = {
    PlcModiconM241: 'plc-modicon-m241' as ControllerTypeEnum
  };
  export type EquipmentTypeEnum = 'car-wash';
  export const EquipmentTypeEnum = {
    CarWash: 'car-wash' as EquipmentTypeEnum
  };
  export type StatusEnum =
    | 'waiting-for-process'
    | 'processing'
    | 'processing-stopping'
    | 'processing-finished'
    | 'processing-failed';
  export const StatusEnum = {
    WaitingForProcess: 'waiting-for-process' as StatusEnum,
    Processing: 'processing' as StatusEnum,
    ProcessingStopping: 'processing-stopping' as StatusEnum,
    ProcessingFinished: 'processing-finished' as StatusEnum,
    ProcessingFailed: 'processing-failed' as StatusEnum
  };
}
