export const environment = {
  clientUrl: 'https://client.incust.com/',
  clientUrlTest: 'https://testclient.incust.com/',
  apiUrl: 'https://api.incust.com/v1/term',
  apiUrlTest: 'https://testapi.incust.com/v1/term',
  workers: {
    receipt: 'https://mf-invoices.incust.com'
  },
  applicationId: '',
  applicationIdTest: '',
  mode: 'dev',
  i18nLang: [
    {
      lang: 'en',
      display: 'English',
      defaultCode: 'US'
    },
    {
      lang: 'uk',
      display: 'Українська',
      defaultCode: 'UA'
    },
    {
      lang: 'ru',
      display: 'Русский',
      defaultCode: 'RU'
    },

    {
      lang: 'az',
      display: 'Azerbaijani',
      defaultCode: 'AZ'
    },
    {
      lang: 'pl',
      display: 'Polski',
      defaultCode: 'PL'
    },
    {
      lang: 'sk',
      display: 'Slovak',
      defaultCode: 'SK'
    },
    {
      lang: 'fr',
      display: 'Français',
      defaultCode: 'FR'
    },
    {
      lang: 'hy',
      display: 'Հայերեն',
      defaultCode: 'HY'
    }
  ],
  i18nDefaultLanguage: 'en',
  appName: 'inCust',
  appDomain: 'incust.com',
  packageName: 'com.incust.register2',
  appleStoreId: '1462567188',
  isFileSystem: false,
  colorLogin: 'secondary'
};
