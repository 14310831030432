/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface LoyaltyOnlineStore {
  /**
   * Object active flag
   */
  active?: boolean;
  /**
   * Additional customer info
   */
  additional_customer_info?: string;
  /**
   * Online store public title
   */
  title?: string;
}
