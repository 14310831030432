/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PriceCustomerSpecialAccountAccess } from './priceCustomerSpecialAccountAccess';
import { PriceCustomerSpecialAccount } from './priceCustomerSpecialAccount';
import { PriceTaxes } from './priceTaxes';
import { PriceMargins } from './priceMargins';
import { PriceSpecialAccount } from './priceSpecialAccount';

export interface CustomerPrice {
  /**
   * Alias for special_account_id - DEPRECATED
   */
  account_id?: string | null;
  /**
   * Is the price applicable to the customer (readonly)
   */
  applicable?: boolean | null;
  customer_access_account?: PriceCustomerSpecialAccountAccess;
  /**
   * Customer special account ID (optional)
   */
  customer_access_account_id?: string | null;
  customer_account?: PriceCustomerSpecialAccount;
  /**
   * Customer special account ID (optional)
   */
  customer_account_id?: string | null;
  /**
   * Object (special account, access or currency ISO code) ID
   */
  id?: string | null;
  margins?: PriceMargins;
  /**
   * Price value
   */
  price?: number | null;
  /**
   * Base price from which the price is calculated
   */
  price_base?: number | null;
  /**
   * Customer (calculated by modifiers) price (optional)
   */
  price_customer?: number | null;
  /**
   * Number of decimal digits in price value
   */
  price_decimal_digits?: number | null;
  /**
   * Sale (check, receipt) price (optional)
   */
  price_sale?: number | null;
  special_account?: PriceSpecialAccount;
  /**
   * Special account ID (optional)
   */
  special_account_id?: string | null;
  taxes?: PriceTaxes;
  /**
   * Object (special account or access) type
   */
  type?: CustomerPrice.TypeEnum | null;
}
export namespace CustomerPrice {
  export type TypeEnum =
    | 'currency'
    | 'retail-special-account'
    | 'retail-special-account-access'
    | 'corporate-special-account'
    | 'corporate-special-account-access';
  export const TypeEnum = {
    Currency: 'currency' as TypeEnum,
    RetailSpecialAccount: 'retail-special-account' as TypeEnum,
    RetailSpecialAccountAccess: 'retail-special-account-access' as TypeEnum,
    CorporateSpecialAccount: 'corporate-special-account' as TypeEnum,
    CorporateSpecialAccountAccess:
      'corporate-special-account-access' as TypeEnum
  };
}
