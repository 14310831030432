/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserIdentifier } from './userIdentifier';

export interface Customer {
  /**
   * User birth date
   */
  birth_date?: string | null;
  /**
   * User country ISO code
   */
  country?: string | null;
  /**
   * User gender
   */
  gender?: Customer.GenderEnum | null;
  /**
   * User ID
   */
  id?: string | null;
  /**
   * User identification data list
   */
  identification: Array<UserIdentifier>;
  /**
   * User language ISO code
   */
  language?: string | null;
  /**
   * User name
   */
  name?: string | null;
}
export namespace Customer {
  export type GenderEnum = 'male' | 'female' | 'other';
  export const GenderEnum = {
    Male: 'male' as GenderEnum,
    Female: 'female' as GenderEnum,
    Other: 'other' as GenderEnum
  };
}
