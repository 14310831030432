/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TourCardCategory } from './tourCardCategory';

export interface TourCardService {
  /**
   * Available sevice account amount
   */
  available_amount?: number;
  /**
   * Service availability flag
   */
  available_now?: number;
  /**
   * Number of times available for use
   */
  available_times?: number;
  category?: TourCardCategory;
  /**
   * Decrement step of account amount
   */
  decrement_step?: number;
  /**
   * Service ID
   */
  id?: string;
  /**
   * URL of object image
   */
  image?: string;
  /**
   * Public description
   */
  public_description?: string;
  /**
   * Public title
   */
  public_title?: string;
  /**
   * Usage limit per loyalty
   */
  usage_limit_per_loyalty?: number;
  /**
   * Usage limit per loyalty period type
   */
  usage_limit_per_loyalty_period_type?: TourCardService.UsageLimitPerLoyaltyPeriodTypeEnum;
  /**
   * Usage limit per loyalty period value
   */
  usage_limit_per_loyalty_period_value?: number;
  /**
   * Usage limit per POS
   */
  usage_limit_per_pos?: number;
  /**
   * Usage limit per POS period type
   */
  usage_limit_per_pos_period_type?: TourCardService.UsageLimitPerPosPeriodTypeEnum;
  /**
   * Usage limit per POS period value
   */
  usage_limit_per_pos_period_value?: number;
  /**
   * Number of service usage by customer (optional)
   */
  used_times?: number;
}
export namespace TourCardService {
  export type UsageLimitPerLoyaltyPeriodTypeEnum =
    | 'all-card-time'
    | 'hour'
    | 'day'
    | 'month';
  export const UsageLimitPerLoyaltyPeriodTypeEnum = {
    AllCardTime: 'all-card-time' as UsageLimitPerLoyaltyPeriodTypeEnum,
    Hour: 'hour' as UsageLimitPerLoyaltyPeriodTypeEnum,
    Day: 'day' as UsageLimitPerLoyaltyPeriodTypeEnum,
    Month: 'month' as UsageLimitPerLoyaltyPeriodTypeEnum
  };
  export type UsageLimitPerPosPeriodTypeEnum =
    | 'all-card-time'
    | 'hour'
    | 'day'
    | 'month';
  export const UsageLimitPerPosPeriodTypeEnum = {
    AllCardTime: 'all-card-time' as UsageLimitPerPosPeriodTypeEnum,
    Hour: 'hour' as UsageLimitPerPosPeriodTypeEnum,
    Day: 'day' as UsageLimitPerPosPeriodTypeEnum,
    Month: 'month' as UsageLimitPerPosPeriodTypeEnum
  };
}
