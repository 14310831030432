/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SpecialAccount {
  /**
   * Active flag (optional)
   */
  active?: number | null;
  /**
   * Corporate accoiunt flag (optional)
   */
  corporate?: number | null;
  /**
   * Credit limit (only for \'credit\' accounts) (optional)
   */
  credit_limit?: number | null;
  /**
   * Credit type (only for corporate accounts) (optional)
   */
  credit_type?: SpecialAccount.CreditTypeEnum | null;
  /**
   * Currency ISO code
   */
  currency?: string | null;
  /**
   * Account applicable goods (optional)
   */
  goods_items?: Array<string> | null;
  /**
   * Field id
   */
  id?: string | null;
  /**
   * Title to show (optional)
   */
  public_title?: string | null;
  /**
   * Account title
   */
  title?: string | null;
  /**
   * Account type
   */
  type?: SpecialAccount.TypeEnum | null;
  /**
   * The limit of redemption from customer account without an confirmation (optional)
   */
  unconfirmed_redeem_amount?: number | null;
}
export namespace SpecialAccount {
  export type CreditTypeEnum = 'debit' | 'credit' | 'credit-unlimited';
  export const CreditTypeEnum = {
    Debit: 'debit' as CreditTypeEnum,
    Credit: 'credit' as CreditTypeEnum,
    CreditUnlimited: 'credit-unlimited' as CreditTypeEnum
  };
  export type TypeEnum = 'money' | 'goods' | 'punches';
  export const TypeEnum = {
    Money: 'money' as TypeEnum,
    Goods: 'goods' as TypeEnum,
    Punches: 'punches' as TypeEnum
  };
}
