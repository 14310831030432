/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ProbeAlarm {
  /**
   * Alarm rised date and time
   */
  created?: string | null;
  /**
   * Alarm type
   */
  type?: ProbeAlarm.TypeEnum | null;
}
export namespace ProbeAlarm {
  export type TypeEnum =
    | 'critical-high-product'
    | 'critical-low-product'
    | 'high-product'
    | 'low-product'
    | 'high-water';
  export const TypeEnum = {
    CriticalHighProduct: 'critical-high-product' as TypeEnum,
    CriticalLowProduct: 'critical-low-product' as TypeEnum,
    HighProduct: 'high-product' as TypeEnum,
    LowProduct: 'low-product' as TypeEnum,
    HighWater: 'high-water' as TypeEnum
  };
}
