/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { IncustControllersTermApiTourCardAddRequest } from '../model/incustControllersTermApiTourCardAddRequest';
// @ts-ignore
import { IncustControllersTermApiTourCardInfoWoUserRequest } from '../model/incustControllersTermApiTourCardInfoWoUserRequest';
// @ts-ignore
import { MessageResponse } from '../model/messageResponse';
// @ts-ignore
import { TourCard } from '../model/tourCard';
// @ts-ignore
import { TourCardServiceUse } from '../model/tourCardServiceUse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class TouristCardsService {
  protected basePath = '/v1/term';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substring(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Add tourist card for user and return tourist card list
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param data Tourist card and service Ids
   * @param userIdType Type of user identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardAdd(
    userIdValue: string,
    data: IncustControllersTermApiTourCardAddRequest,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<TourCard>>;
  public incustControllersTermApiTourCardAdd(
    userIdValue: string,
    data: IncustControllersTermApiTourCardAddRequest,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<TourCard>>>;
  public incustControllersTermApiTourCardAdd(
    userIdValue: string,
    data: IncustControllersTermApiTourCardAddRequest,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<TourCard>>>;
  public incustControllersTermApiTourCardAdd(
    userIdValue: string,
    data: IncustControllersTermApiTourCardAddRequest,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardAdd.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardAdd.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/user/cards/add`;
    return this.httpClient.request<Array<TourCard>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add tourist card for user and return tourist card list
   * @param userIdValue User phone number
   * @param userIdType Type of user identity
   * @param data Tourist card and service Ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardAddEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: IncustControllersTermApiTourCardAddRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<TourCard>>;
  public incustControllersTermApiTourCardAddEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: IncustControllersTermApiTourCardAddRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<TourCard>>>;
  public incustControllersTermApiTourCardAddEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: IncustControllersTermApiTourCardAddRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<TourCard>>>;
  public incustControllersTermApiTourCardAddEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: IncustControllersTermApiTourCardAddRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardAddEx.'
      );
    }
    if (userIdType === null || userIdType === undefined) {
      throw new Error(
        'Required parameter userIdType was null or undefined when calling incustControllersTermApiTourCardAddEx.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardAddEx.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/${this.configuration.encodeParam({
      name: 'userIdType',
      value: userIdType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'phone' | 'qr' | 'email' | 'card' | 'temporary-card' | 'id' | 'external-id' | 'social-network' | 'ssn' | 'itin' | 'itn'",
      dataFormat: undefined
    })}/cards/add`;
    return this.httpClient.request<Array<TourCard>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Add tourist card for user and return tourist card list
   * @param userIdValue User phone number
   * @param data Tourist card and service Ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardAddSimple(
    userIdValue: string,
    data: IncustControllersTermApiTourCardAddRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<TourCard>>;
  public incustControllersTermApiTourCardAddSimple(
    userIdValue: string,
    data: IncustControllersTermApiTourCardAddRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<TourCard>>>;
  public incustControllersTermApiTourCardAddSimple(
    userIdValue: string,
    data: IncustControllersTermApiTourCardAddRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<TourCard>>>;
  public incustControllersTermApiTourCardAddSimple(
    userIdValue: string,
    data: IncustControllersTermApiTourCardAddRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardAddSimple.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardAddSimple.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/cards/add`;
    return this.httpClient.request<Array<TourCard>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Search tourist card by code and return tourist card info
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param data Tourist card code
   * @param userIdType Type of user identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardInfoWUser(
    userIdValue: string,
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<TourCard>;
  public incustControllersTermApiTourCardInfoWUser(
    userIdValue: string,
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<TourCard>>;
  public incustControllersTermApiTourCardInfoWUser(
    userIdValue: string,
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<TourCard>>;
  public incustControllersTermApiTourCardInfoWUser(
    userIdValue: string,
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardInfoWUser.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardInfoWUser.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/user/cards/code`;
    return this.httpClient.request<TourCard>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Search tourist card by code and return tourist card info
   * @param userIdValue User phone number
   * @param userIdType Type of user identity
   * @param data Tourist card code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardInfoWUserEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<TourCard>;
  public incustControllersTermApiTourCardInfoWUserEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<TourCard>>;
  public incustControllersTermApiTourCardInfoWUserEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<TourCard>>;
  public incustControllersTermApiTourCardInfoWUserEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardInfoWUserEx.'
      );
    }
    if (userIdType === null || userIdType === undefined) {
      throw new Error(
        'Required parameter userIdType was null or undefined when calling incustControllersTermApiTourCardInfoWUserEx.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardInfoWUserEx.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/${this.configuration.encodeParam({
      name: 'userIdType',
      value: userIdType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'phone' | 'qr' | 'email' | 'card' | 'temporary-card' | 'id' | 'external-id' | 'social-network' | 'ssn' | 'itin' | 'itn'",
      dataFormat: undefined
    })}/cards/code`;
    return this.httpClient.request<TourCard>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Search tourist card by code and return tourist card info
   * @param userIdValue User phone number
   * @param data Tourist card code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardInfoWUserSimple(
    userIdValue: string,
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<TourCard>;
  public incustControllersTermApiTourCardInfoWUserSimple(
    userIdValue: string,
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<TourCard>>;
  public incustControllersTermApiTourCardInfoWUserSimple(
    userIdValue: string,
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<TourCard>>;
  public incustControllersTermApiTourCardInfoWUserSimple(
    userIdValue: string,
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardInfoWUserSimple.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardInfoWUserSimple.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/cards/code`;
    return this.httpClient.request<TourCard>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Search tourist card by code and return tourist card info
   * @param data Tourist card code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardInfoWoUser(
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<TourCard>;
  public incustControllersTermApiTourCardInfoWoUser(
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<TourCard>>;
  public incustControllersTermApiTourCardInfoWoUser(
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<TourCard>>;
  public incustControllersTermApiTourCardInfoWoUser(
    data: IncustControllersTermApiTourCardInfoWoUserRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardInfoWoUser.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/cards/code`;
    return this.httpClient.request<TourCard>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Use tourist card service
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param data Tourist card and service Ids
   * @param userIdType Type of user identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardServiceUseWUser(
    userIdValue: string,
    data: TourCardServiceUse,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<TourCard>;
  public incustControllersTermApiTourCardServiceUseWUser(
    userIdValue: string,
    data: TourCardServiceUse,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<TourCard>>;
  public incustControllersTermApiTourCardServiceUseWUser(
    userIdValue: string,
    data: TourCardServiceUse,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<TourCard>>;
  public incustControllersTermApiTourCardServiceUseWUser(
    userIdValue: string,
    data: TourCardServiceUse,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardServiceUseWUser.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardServiceUseWUser.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/user/cards/service/use`;
    return this.httpClient.request<TourCard>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Use tourist card service
   * @param userIdValue User phone number
   * @param userIdType Type of user identity
   * @param data Tourist card and service Ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardServiceUseWUserEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: TourCardServiceUse,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<TourCard>;
  public incustControllersTermApiTourCardServiceUseWUserEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: TourCardServiceUse,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<TourCard>>;
  public incustControllersTermApiTourCardServiceUseWUserEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: TourCardServiceUse,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<TourCard>>;
  public incustControllersTermApiTourCardServiceUseWUserEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: TourCardServiceUse,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardServiceUseWUserEx.'
      );
    }
    if (userIdType === null || userIdType === undefined) {
      throw new Error(
        'Required parameter userIdType was null or undefined when calling incustControllersTermApiTourCardServiceUseWUserEx.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardServiceUseWUserEx.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/${this.configuration.encodeParam({
      name: 'userIdType',
      value: userIdType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'phone' | 'qr' | 'email' | 'card' | 'temporary-card' | 'id' | 'external-id' | 'social-network' | 'ssn' | 'itin' | 'itn'",
      dataFormat: undefined
    })}/cards/service/use`;
    return this.httpClient.request<TourCard>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Use tourist card service
   * @param userIdValue User phone number
   * @param data Tourist card and service Ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardServiceUseWUserSimple(
    userIdValue: string,
    data: TourCardServiceUse,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<TourCard>;
  public incustControllersTermApiTourCardServiceUseWUserSimple(
    userIdValue: string,
    data: TourCardServiceUse,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<TourCard>>;
  public incustControllersTermApiTourCardServiceUseWUserSimple(
    userIdValue: string,
    data: TourCardServiceUse,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<TourCard>>;
  public incustControllersTermApiTourCardServiceUseWUserSimple(
    userIdValue: string,
    data: TourCardServiceUse,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardServiceUseWUserSimple.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardServiceUseWUserSimple.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/cards/service/use`;
    return this.httpClient.request<TourCard>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Use tourist card service
   * @param data Tourist card and service Ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardServiceUseWoUser(
    data: TourCardServiceUse,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<TourCard>;
  public incustControllersTermApiTourCardServiceUseWoUser(
    data: TourCardServiceUse,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<TourCard>>;
  public incustControllersTermApiTourCardServiceUseWoUser(
    data: TourCardServiceUse,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<TourCard>>;
  public incustControllersTermApiTourCardServiceUseWoUser(
    data: TourCardServiceUse,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTourCardServiceUseWoUser.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/cards/service/use`;
    return this.httpClient.request<TourCard>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return tourist cards list
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param userIdType Type of user identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardsList(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<TourCard>>;
  public incustControllersTermApiTourCardsList(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<TourCard>>>;
  public incustControllersTermApiTourCardsList(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<TourCard>>>;
  public incustControllersTermApiTourCardsList(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardsList.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/user/cards`;
    return this.httpClient.request<Array<TourCard>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return tourist cards list
   * @param userIdValue User phone number
   * @param userIdType Type of user identity
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardsListEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<TourCard>>;
  public incustControllersTermApiTourCardsListEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<TourCard>>>;
  public incustControllersTermApiTourCardsListEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<TourCard>>>;
  public incustControllersTermApiTourCardsListEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardsListEx.'
      );
    }
    if (userIdType === null || userIdType === undefined) {
      throw new Error(
        'Required parameter userIdType was null or undefined when calling incustControllersTermApiTourCardsListEx.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/${this.configuration.encodeParam({
      name: 'userIdType',
      value: userIdType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'phone' | 'qr' | 'email' | 'card' | 'temporary-card' | 'id' | 'external-id' | 'social-network' | 'ssn' | 'itin' | 'itn'",
      dataFormat: undefined
    })}/cards`;
    return this.httpClient.request<Array<TourCard>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return tourist cards list
   * @param userIdValue User phone number
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTourCardsListSimple(
    userIdValue: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<TourCard>>;
  public incustControllersTermApiTourCardsListSimple(
    userIdValue: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<TourCard>>>;
  public incustControllersTermApiTourCardsListSimple(
    userIdValue: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<TourCard>>>;
  public incustControllersTermApiTourCardsListSimple(
    userIdValue: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiTourCardsListSimple.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/tour_card/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/cards`;
    return this.httpClient.request<Array<TourCard>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }
}
