import { Component, inject, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { BaseAbstractPage } from '../base.abstract.page';
import { ModeLayout } from '../../layouts/mode.layout';
import { SettingsService } from '@incust/api-term';

@Component({
  selector: 'app-auto-choice-page',
  templateUrl: 'auto-choice.page.html',
  styleUrls: ['auto-choice.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    ModeLayout
  ]
})
export class AutoChoicePage extends BaseAbstractPage implements OnInit {
  private settingsApiService = inject(SettingsService);

  public form!: FormGroup;

  ngOnInit() {
    this.form = new FormGroup({
      idValue: new FormControl()
    });
  }

  callApi() {
    this.settingsApiService
      .incustControllersTermApiSettingsApplicationGet()
      .subscribe((result) => {
        console.log(2222, result);
      });
  }
}
