/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CorporateCustomerSpecialAccount } from './corporateCustomerSpecialAccount';
import { CorporateAccountAccessLimits } from './corporateAccountAccessLimits';

export interface CorporateCustomerSpecialAccountAccess {
  /**
   * Amount of funds on the account available for using (null if unlimited)
   */
  available_amount?: number;
  corporate_customer_special_account?: CorporateCustomerSpecialAccount;
  /**
   * Account applicable goods filter
   */
  goods_items?: Array<string>;
  /**
   * Access ID
   */
  id?: string;
  limits?: CorporateAccountAccessLimits;
  /**
   * Odometer value needed flag
   */
  odometer?: CorporateCustomerSpecialAccountAccess.OdometerEnum;
  /**
   * Additional PIN-code for access to the account exists
   */
  security_code?: number;
  /**
   * Vehicle ID needed flag
   */
  vehicle_id?: CorporateCustomerSpecialAccountAccess.VehicleIdEnum;
}
export namespace CorporateCustomerSpecialAccountAccess {
  export type OdometerEnum = 'no' | 'yes' | 'possible';
  export const OdometerEnum = {
    No: 'no' as OdometerEnum,
    Yes: 'yes' as OdometerEnum,
    Possible: 'possible' as OdometerEnum
  };
  export type VehicleIdEnum = 'no' | 'yes' | 'possible';
  export const VehicleIdEnum = {
    No: 'no' as VehicleIdEnum,
    Yes: 'yes' as VehicleIdEnum,
    Possible: 'possible' as VehicleIdEnum
  };
}
