/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PriceGroupMasterPrice {
  /**
   * Currency ISO code (readonly)
   */
  currency?: string | null;
  /**
   * Master price unique ID
   */
  id?: string | null;
  /**
   * Goods item ID
   */
  item_id?: string | null;
  /**
   * Items price decimal digits (readonly)
   */
  price_decimal_digits?: number;
  /**
   * Price group ID
   */
  price_group_id?: string | null;
  /**
   * Date and time from which master price is valid
   */
  starting?: string | null;
  /**
   * Starting value time zone db name
   */
  time_zone?: string | null;
  /**
   * Master price value
   */
  value?: number | null;
}
