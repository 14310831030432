/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ExternalBankCardsEDCTypes = 'auto' | 'credit' | 'debit' | 'gift';

export const ExternalBankCardsEDCTypes = {
  Auto: 'auto' as ExternalBankCardsEDCTypes,
  Credit: 'credit' as ExternalBankCardsEDCTypes,
  Debit: 'debit' as ExternalBankCardsEDCTypes,
  Gift: 'gift' as ExternalBankCardsEDCTypes
};
