/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type PriceExplainOriginValueOption = 'discount' | 'markup';

export const PriceExplainOriginValueOption = {
  Discount: 'discount' as PriceExplainOriginValueOption,
  Markup: 'markup' as PriceExplainOriginValueOption
};
