import { Component, Input } from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar
} from '@ionic/angular/standalone';

@Component({
  standalone: true,
  selector: 'app-mode-layout',
  template: `
    <ion-header mode="ios" translucent>
      <ion-toolbar mode="ios" class="ion-text-center">
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button auto-hide="false"></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <ng-content></ng-content>
    </ion-content>
  `,
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonMenuButton
  ]
})
export class ModeLayout {
  @Input() title!: string;
}
