/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerPaymentSystemType } from './customerPaymentSystemType';

/**
 * Payment status details
 */
export interface PaymentStatus {
  /**
   * To pay amount (optional)
   */
  amount?: number | null;
  /**
   * Customer fee amount (optional, only for \'mobile-app\' type)
   */
  customer_fee_amount?: number | null;
  /**
   * Payment Id
   */
  id?: string | null;
  /**
   * Payment amount (optional)
   */
  invoice?: number;
  /**
   * Secure type (optional)
   */
  secure?: PaymentStatus.SecureEnum | null;
  /**
   * Operation status
   */
  status?: PaymentStatus.StatusEnum | null;
  type?: CustomerPaymentSystemType;
}
export namespace PaymentStatus {
  export type SecureEnum = 'none' | 'otp' | '3ds';
  export const SecureEnum = {
    None: 'none' as SecureEnum,
    Otp: 'otp' as SecureEnum,
    _3ds: '3ds' as SecureEnum
  };
  export type StatusEnum =
    | 'inprocess'
    | 'success'
    | 'fail'
    | 'verification'
    | 'registered'
    | 'canceled'
    | 'error'
    | 'authorized'
    | 'unknown';
  export const StatusEnum = {
    Inprocess: 'inprocess' as StatusEnum,
    Success: 'success' as StatusEnum,
    Fail: 'fail' as StatusEnum,
    Verification: 'verification' as StatusEnum,
    Registered: 'registered' as StatusEnum,
    Canceled: 'canceled' as StatusEnum,
    Error: 'error' as StatusEnum,
    Authorized: 'authorized' as StatusEnum,
    Unknown: 'unknown' as StatusEnum
  };
}
