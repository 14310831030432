/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RouteOperationListResultSummary {
  /**
   * Total route operations count
   */
  total_rows_count?: number;
}
