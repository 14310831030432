/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ConsentConfirmation {
  /**
   * Confirmation code (for types pin, email)
   */
  code?: string;
  /**
   * Comment
   */
  comment?: string;
  /**
   * Confirmation type
   */
  confirmation: ConsentConfirmation.ConfirmationEnum;
}
export namespace ConsentConfirmation {
  export type ConfirmationEnum = 'pin' | 'email' | 'interface' | 'business';
  export const ConfirmationEnum = {
    Pin: 'pin' as ConfirmationEnum,
    Email: 'email' as ConfirmationEnum,
    Interface: 'interface' as ConfirmationEnum,
    Business: 'business' as ConfirmationEnum
  };
}
