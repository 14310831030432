/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type LoyaltyRuleDependsTimeZone = 'system' | 'pos';

export const LoyaltyRuleDependsTimeZone = {
  System: 'system' as LoyaltyRuleDependsTimeZone,
  Pos: 'pos' as LoyaltyRuleDependsTimeZone
};
