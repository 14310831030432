/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type PriceExplainValueSource =
  | 'defined'
  | 'received'
  | 'calculated'
  | 'reseted'
  | 'price-by-item'
  | 'price-by-special-account'
  | 'master-price-by-date'
  | 'price-group-tax-by-item'
  | 'price-group-tax-default'
  | 'price-group-tax-default-base'
  | 'terminal-additional-cost-by-item'
  | 'terminal-additional-cost-default'
  | 'terminal-additional-cost-default-base'
  | 'corporate-account-price-option-by-item'
  | 'corporate-category-account-price-option-by-item'
  | 'retail-category-account-price-option-by-item'
  | 'retail-category-currency-price-option-by-item';

export const PriceExplainValueSource = {
  Defined: 'defined' as PriceExplainValueSource,
  Received: 'received' as PriceExplainValueSource,
  Calculated: 'calculated' as PriceExplainValueSource,
  Reseted: 'reseted' as PriceExplainValueSource,
  PriceByItem: 'price-by-item' as PriceExplainValueSource,
  PriceBySpecialAccount: 'price-by-special-account' as PriceExplainValueSource,
  MasterPriceByDate: 'master-price-by-date' as PriceExplainValueSource,
  PriceGroupTaxByItem: 'price-group-tax-by-item' as PriceExplainValueSource,
  PriceGroupTaxDefault: 'price-group-tax-default' as PriceExplainValueSource,
  PriceGroupTaxDefaultBase:
    'price-group-tax-default-base' as PriceExplainValueSource,
  TerminalAdditionalCostByItem:
    'terminal-additional-cost-by-item' as PriceExplainValueSource,
  TerminalAdditionalCostDefault:
    'terminal-additional-cost-default' as PriceExplainValueSource,
  TerminalAdditionalCostDefaultBase:
    'terminal-additional-cost-default-base' as PriceExplainValueSource,
  CorporateAccountPriceOptionByItem:
    'corporate-account-price-option-by-item' as PriceExplainValueSource,
  CorporateCategoryAccountPriceOptionByItem:
    'corporate-category-account-price-option-by-item' as PriceExplainValueSource,
  RetailCategoryAccountPriceOptionByItem:
    'retail-category-account-price-option-by-item' as PriceExplainValueSource,
  RetailCategoryCurrencyPriceOptionByItem:
    'retail-category-currency-price-option-by-item' as PriceExplainValueSource
};
