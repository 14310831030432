/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Transaction } from './transaction';
import { LoyaltyOnlineStore } from './loyaltyOnlineStore';
import { Check } from './check';
import { OrderFuelDelivery } from './orderFuelDelivery';
import { RouteTerminal } from './routeTerminal';
import { Shipping } from './shipping';
import { Loyalty } from './loyalty';

export interface Order {
  check?: Check;
  /**
   * Comment
   */
  comment?: string | null;
  /**
   * Created order time
   */
  created?: string | null;
  /**
   * Delivered time
   */
  delivered?: string | null;
  /**
   * Delivery method ID
   */
  delivery_id?: string | null;
  fuel_delivery?: OrderFuelDelivery;
  /**
   * Order ID
   */
  id?: string | null;
  loyalty?: Loyalty;
  /**
   * Loyalty ID
   */
  loyalty_id?: string | null;
  /**
   * Order number
   */
  number?: string | null;
  online_store?: LoyaltyOnlineStore;
  /**
   * Order paid flag
   */
  paid?: boolean | null;
  /**
   * Order payment method
   */
  payment_method?: Order.PaymentMethodEnum | null;
  /**
   * Pos ID
   */
  pos_id?: string | null;
  route?: RouteTerminal;
  shipping?: Shipping;
  /**
   * Order status
   */
  status?: Order.StatusEnum | null;
  /**
   * Terminal ID
   */
  terminal_id?: string | null;
  /**
   * Time zone
   */
  time_zone?: string | null;
  /**
   * Order transactions
   */
  transaction?: Array<Transaction> | null;
  transaction_actual?: Transaction;
  /**
   * Order type
   */
  type?: Order.TypeEnum | null;
  /**
   * User ID
   */
  user_id?: string | null;
}
export namespace Order {
  export type PaymentMethodEnum =
    | 'payment-at-checkout'
    | 'payment-upon-receipt';
  export const PaymentMethodEnum = {
    AtCheckout: 'payment-at-checkout' as PaymentMethodEnum,
    UponReceipt: 'payment-upon-receipt' as PaymentMethodEnum
  };
  export type StatusEnum = 'created' | 'inprocess' | 'delivered' | 'canceled';
  export const StatusEnum = {
    Created: 'created' as StatusEnum,
    Inprocess: 'inprocess' as StatusEnum,
    Delivered: 'delivered' as StatusEnum,
    Canceled: 'canceled' as StatusEnum
  };
  export type TypeEnum = 'online-store' | 'fuel-taxi';
  export const TypeEnum = {
    OnlineStore: 'online-store' as TypeEnum,
    FuelTaxi: 'fuel-taxi' as TypeEnum
  };
}
