/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdType } from './idType';

export interface CustomerBenefitsTopUpOperationData {
  /**
   * Amount credited benefits
   */
  amount: number;
  /**
   * Comment
   */
  comment?: string;
  /**
   * Bonus points currency ISO string
   */
  currency?: string;
  /**
   * Value of promotional bonuses expire date
   */
  expire?: string;
  /**
   * User identifier (phone number, card code, QR code). Minimum 3 chars
   */
  id: string;
  id_type?: IdType;
  /**
   * Alias of expire field (DEPRECATED)
   */
  promotional_bonuses_expire_date?: string;
  /**
   * Alias of starting field (DEPRECATED)
   */
  promotional_bonuses_starting_date?: string;
  /**
   * Special account ID
   */
  special_account_id?: string;
  /**
   * Value of bonuses starting date
   */
  starting?: string;
  /**
   * Benefit type
   */
  type: CustomerBenefitsTopUpOperationData.TypeEnum;
}
export namespace CustomerBenefitsTopUpOperationData {
  export type TypeEnum =
    | 'regular-bonuses'
    | 'promotional-bonuses'
    | 'special-account';
  export const TypeEnum = {
    RegularBonuses: 'regular-bonuses' as TypeEnum,
    PromotionalBonuses: 'promotional-bonuses' as TypeEnum,
    SpecialAccount: 'special-account' as TypeEnum
  };
}
