/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CorporateAccountAccessLimits {
  /**
   * Amount of funds on the account available for using (null if unlimited)
   */
  account_available_amount?: number;
  /**
   * Corporate customer account day limit (if 0 - unlimited)
   */
  account_day_limit?: number;
  /**
   * Corporate customer account day available amount, not for unlimited account (null if unlimited)
   */
  account_day_limit_available?: number;
  /**
   * Corporate customer account day used amount
   */
  account_day_limit_used?: number;
  /**
   * Corporate customer account month limit (if 0 - unlimited)
   */
  account_month_limit?: number;
  /**
   * Corporate customer account month available amount, not for unlimited account (null if unlimited)
   */
  account_month_limit_available?: number;
  /**
   * Corporate customer account month used amount
   */
  account_month_limit_used?: number;
  /**
   * User access to the account day limit (if 0 - unlimited)
   */
  user_day_limit?: number;
  /**
   * User access to the account day available amount, not for unlimited account (null if unlimited)
   */
  user_day_limit_available?: number;
  /**
   * User access to the account day used amount
   */
  user_day_limit_used?: number;
  /**
   * User access to the account month limit (if 0 - unlimited)
   */
  user_month_limit?: number;
  /**
   * User access to the account month available amount, not for unlimited account (null if unlimited)
   */
  user_month_limit_available?: number;
  /**
   * User access to the account month used amount
   */
  user_month_limit_used?: number;
}
