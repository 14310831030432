/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { MessageResponse } from '../model/messageResponse';
// @ts-ignore
import { UserExternalForm } from '../model/userExternalForm';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class ExternalFormsService {
  protected basePath = '/v1/term';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substring(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Add user filled external form
   * @param externalFormId External form Id
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param data Filled form data
   * @param userIdType Type of user identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiExternalFormForUserAdd(
    externalFormId: string,
    userIdValue: string,
    data: UserExternalForm,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<UserExternalForm>;
  public incustControllersTermApiExternalFormForUserAdd(
    externalFormId: string,
    userIdValue: string,
    data: UserExternalForm,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<UserExternalForm>>;
  public incustControllersTermApiExternalFormForUserAdd(
    externalFormId: string,
    userIdValue: string,
    data: UserExternalForm,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<UserExternalForm>>;
  public incustControllersTermApiExternalFormForUserAdd(
    externalFormId: string,
    userIdValue: string,
    data: UserExternalForm,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (externalFormId === null || externalFormId === undefined) {
      throw new Error(
        'Required parameter externalFormId was null or undefined when calling incustControllersTermApiExternalFormForUserAdd.'
      );
    }
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiExternalFormForUserAdd.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiExternalFormForUserAdd.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/external_forms/${this.configuration.encodeParam({
      name: 'externalFormId',
      value: externalFormId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: 'uuid'
    })}/user`;
    return this.httpClient.request<UserExternalForm>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return external form template (prefilled or no) for user
   * @param externalFormId External form Id
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param userIdType Type of user identifier
   * @param prefilled Return prefilled (based on last filled form if exists) form
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiExternalFormForUserGet(
    externalFormId: string,
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    prefilled?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<UserExternalForm>;
  public incustControllersTermApiExternalFormForUserGet(
    externalFormId: string,
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    prefilled?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<UserExternalForm>>;
  public incustControllersTermApiExternalFormForUserGet(
    externalFormId: string,
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    prefilled?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<UserExternalForm>>;
  public incustControllersTermApiExternalFormForUserGet(
    externalFormId: string,
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    prefilled?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (externalFormId === null || externalFormId === undefined) {
      throw new Error(
        'Required parameter externalFormId was null or undefined when calling incustControllersTermApiExternalFormForUserGet.'
      );
    }
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiExternalFormForUserGet.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }
    if (prefilled !== undefined && prefilled !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>prefilled,
        'prefilled'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/external_forms/${this.configuration.encodeParam({
      name: 'externalFormId',
      value: externalFormId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: 'uuid'
    })}/user`;
    return this.httpClient.request<UserExternalForm>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }
}
