/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Grade data
 */
export interface FuelGradeRec {
  /**
   * Grade code
   */
  code?: string;
  /**
   * Grade ID
   */
  id?: string;
  /**
   * Internal grade ID
   */
  internal_id?: string;
}
