/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PinConfirmationResponseInfo {
  /**
   * Confirmation channel
   */
  channel?: PinConfirmationResponseInfo.ChannelEnum;
  /**
   * PIN-code (masked)
   */
  code?: string;
  /**
   * PIN-code length
   */
  length?: number;
  /**
   * Recipien address
   */
  recipient?: string;
}
export namespace PinConfirmationResponseInfo {
  export type ChannelEnum =
    | 'sms'
    | 'email'
    | 'external-system'
    | 'external-verification-service';
  export const ChannelEnum = {
    Sms: 'sms' as ChannelEnum,
    Email: 'email' as ChannelEnum,
    ExternalSystem: 'external-system' as ChannelEnum,
    ExternalVerificationService: 'external-verification-service' as ChannelEnum
  };
}
