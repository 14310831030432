/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CheckTank {
  /**
   * Title
   */
  address?: string | null;
  /**
   * Tank unique code (read only)
   */
  code?: string | null;
  /**
   * Linked product code (optional, only for linked tank data) (read only)
   */
  goods_code?: string | null;
  /**
   * Latitude in decimal degrees
   */
  latitude?: number | null;
  /**
   * Longitude in decimal degrees
   */
  longitude?: number | null;
  /**
   * Physical DIP Amount
   */
  physical_dip_amount?: number | null;
  /**
   * Tank product volume after operation (read only)
   */
  product_volume_after?: number | null;
  /**
   * Tank product volume before operation (read only)
   */
  product_volume_before?: number | null;
  /**
   * Tank id
   */
  tank_id?: string | null;
  /**
   * Title (read only)
   */
  title?: string | null;
  /**
   * Tank volume (read only)
   */
  volume?: number | null;
}
