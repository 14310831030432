/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type OdometerUnitType = 'K' | 'M' | 'H';

export const OdometerUnitType = {
  K: 'K' as OdometerUnitType,
  M: 'M' as OdometerUnitType,
  H: 'H' as OdometerUnitType
};
