<app-mode-layout title="Scan mode">
  <div class="choice-mode-holder ion-padding">
    <ion-card>
      <div [formGroup]="form">
        <div>
          <ion-input
            type="text"
            aria-label="Identify"
            class="custom-input"
            placeholder="Identify"
            fill="outline"
            formControlName="idValue"
          >
          </ion-input>
        </div>
        <ion-button (click)="callApi()">Call settings</ion-button>
      </div>
    </ion-card>
  </div>
</app-mode-layout>
