/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Job } from './job';

export interface CheckItemAdditionalInfo {
  job?: Job;
  /**
   * Loyalty item unique id related to check item (optional, readonly)
   */
  loyalty_item_id?: string | null;
  /**
   * Check item processing id (optional, readonly)
   */
  processing_id?: string | null;
  /**
   * Number of decimal digits in quantity value (optional, readonly)
   */
  quantity_decimal_digits?: number | null;
  /**
   * Quantity recalculate skipped flag (optional, readonly)
   */
  quantity_recalculate_skipped?: boolean | null;
  /**
   * Quantity recalculate type (optional, readonly)
   */
  quantity_recalculate_type?: CheckItemAdditionalInfo.QuantityRecalculateTypeEnum | null;
}
export namespace CheckItemAdditionalInfo {
  export type QuantityRecalculateTypeEnum =
    | 'adjust-by-amount'
    | 'recalculate-by-amount';
  export const QuantityRecalculateTypeEnum = {
    AdjustByAmount: 'adjust-by-amount' as QuantityRecalculateTypeEnum,
    RecalculateByAmount: 'recalculate-by-amount' as QuantityRecalculateTypeEnum
  };
}
