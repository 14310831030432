/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * URLs for object
 */
export interface FeedbackSourceUrls {
  /**
   * Full URL
   */
  base_url?: string;
  /**
   * Short URL
   */
  short_url?: string;
}
