/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CustomerBonusesAccountActivity {
  /**
   * Bonuses are ready for use
   */
  activated?: boolean | null;
  /**
   * Available bonus points amount
   */
  available_amount?: number | null;
  /**
   * Currensy ISO code
   */
  currency?: string | null;
  /**
   * Date of bonuses expiration
   */
  expired?: string | null;
  /**
   * Bonus points added date and time
   */
  processed?: string | null;
  /**
   * Date of bonuses activation (date from which bonuses are ready for use)
   */
  starting?: string | null;
  /**
   * Unique Id of the transaction that added bonuses
   */
  transaction_id?: string | null;
}
