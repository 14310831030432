/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type UserStatus =
  | 'deleted'
  | 'blocked'
  | 'banned'
  | 'suspended'
  | 'registered'
  | 'activated';

export const UserStatus = {
  Deleted: 'deleted' as UserStatus,
  Blocked: 'blocked' as UserStatus,
  Banned: 'banned' as UserStatus,
  Suspended: 'suspended' as UserStatus,
  Registered: 'registered' as UserStatus,
  Activated: 'activated' as UserStatus
};
