/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BusinessStaff {
  /**
   * Comment (optional)
   */
  comment?: string | null;
  /**
   * Email (optional)
   */
  email?: string | null;
  /**
   * Staff id
   */
  id?: string | null;
  /**
   * Name
   */
  name?: string | null;
  /**
   * Phone (optional)
   */
  phone?: string | null;
  /**
   * Token for next authorization requests (optional, only for sign-in method)
   */
  token?: string;
}
