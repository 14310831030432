/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Transaction } from './transaction';
import { Check } from './check';
import { ReceiptLegalInfo } from './receiptLegalInfo';

export interface TransactionReceipt {
  check?: Check;
  /**
   * Transaction ID
   */
  id?: string | null;
  /**
   * language ISO code
   */
  lang?: string | null;
  legal_info?: ReceiptLegalInfo;
  /**
   * Check print local (by POS time zone) date-time
   */
  print_date?: string | null;
  /**
   * Receipt template
   */
  template?: TransactionReceipt.TemplateEnum | null;
  transaction?: Transaction;
}
export namespace TransactionReceipt {
  export type TemplateEnum = 'business' | 'customer' | 'commercial';
  export const TemplateEnum = {
    Business: 'business' as TemplateEnum,
    Customer: 'customer' as TemplateEnum,
    Commercial: 'commercial' as TemplateEnum
  };
}
