import { DestroyRef, inject } from '@angular/core';
import { Subject } from 'rxjs';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  ToastController
} from '@ionic/angular';

export abstract class BaseAbstractPage {
  protected destroy$ = new Subject<void>();
  protected destroyRef = inject(DestroyRef);
  private modalCtrl = inject(ModalController);
  private loadingCtrl = inject(LoadingController);
  private navCtrl = inject(NavController);
  private alertController = inject(AlertController);
  private toastController = inject(ToastController);

  private loadingElement: any;
  //
  // private constructor() {}

  public async showLoader() {
    if (this.loadingElement) {
      return;
    }
    this.loadingElement = await this.loadingCtrl.create({
      spinner: 'dots'
    });
    await this.loadingElement.present();
  }

  public async hideLoader() {
    if (!this.loadingElement) {
      return;
    }
    await this.loadingElement.dismiss();
  }

  async presentToast(
    message: string,
    position: 'top' | 'middle' | 'bottom',
    color:
      | 'danger'
      | 'dark'
      | 'light'
      | 'medium'
      | 'primary'
      | 'secondary'
      | 'success'
      | 'tertiary'
      | 'warning'
  ) {
    const toast = await this.toastController.create({
      message: message,
      duration: 1500,
      color: color,
      position: position
    });

    await toast.present();
  }
}
