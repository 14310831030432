/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PriceExplainOriginValueOption } from './priceExplainOriginValueOption';
import { PriceExplainOriginValueType } from './priceExplainOriginValueType';
import { PriceExplainValueSource } from './priceExplainValueSource';
import { PriceExplainValueType } from './priceExplainValueType';

export interface PriceExplainDetailingItem {
  /**
   * Number of decimal digits in value
   */
  decimal_digits?: number | null;
  /**
   * Tax/Margin exemption (optional)
   */
  exempt?: boolean | null;
  /**
   * Include in receipt (optional)
   */
  include_in_receipt?: boolean | null;
  /**
   * Initial value of current price level
   */
  initial_value?: number | null;
  /**
   * Reverse calculation used (optional)
   */
  reverse_calculation?: boolean | null;
  source?: PriceExplainValueSource;
  type?: PriceExplainValueType;
  /**
   * Result value of current price level
   */
  value?: number | null;
  /**
   * Option base value type
   */
  value_base_type?: PriceExplainDetailingItem.ValueBaseTypeEnum | null;
  /**
   * Value Code if exists (optional)
   */
  value_code?: string | null;
  /**
   * Value Id if exists (optional)
   */
  value_id?: string | null;
  value_option?: PriceExplainOriginValueOption;
  /**
   * Original (base) value if exists (optional)
   */
  value_origin?: number | null;
  /**
   * Value title if exists (optional)
   */
  value_title?: string | null;
  value_type?: PriceExplainOriginValueType;
}
export namespace PriceExplainDetailingItem {
  export type ValueBaseTypeEnum =
    | 'base-price'
    | 'sale-price'
    | 'posted-price'
    | 'price-including-taxes';
  export const ValueBaseTypeEnum = {
    BasePrice: 'base-price' as ValueBaseTypeEnum,
    SalePrice: 'sale-price' as ValueBaseTypeEnum,
    PostedPrice: 'posted-price' as ValueBaseTypeEnum,
    PriceIncludingTaxes: 'price-including-taxes' as ValueBaseTypeEnum
  };
}
