import { Component, inject, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { BaseAbstractPage } from '../base.abstract.page';
import { AuthHolderService } from '@incust/auth';
import { Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs';

@Component({
  selector: 'app-auth-page',
  templateUrl: 'auth.page.html',
  styleUrls: ['auth.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AuthPage extends BaseAbstractPage implements OnInit {
  public authForm!: FormGroup;
  private authService = inject(AuthHolderService);
  private router = inject(Router);

  ngOnInit() {
    this.authForm = new FormGroup({
      login: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  async signIn() {
    await this.showLoader();
    this.authService
      .login(this.authForm.value)
      .pipe(
        takeUntil(this.destroy$),
        finalize(async () => await this.hideLoader())
      )
      .subscribe({
        next: async (result) => {
          console.log(result);
          await this.router.navigate(['/pos-mode']);
          await this.presentToast('Login successfully', 'top', 'success');
        },
        error: async (error) => {
          await this.presentToast(error.message, 'top', 'danger');
        }
      });
  }
}
