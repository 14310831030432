/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ISO language code / Value pairs
 */
export interface MultiLanguageObject {
  az?: string | null;
  de?: string | null;
  en?: string | null;
  fr?: string | null;
  pl?: string | null;
  ru?: string | null;
  sk?: string | null;
  uk?: string | null;
}
