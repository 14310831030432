/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CorporateStaffPosition {
  /**
   * Position ID
   */
  id?: string;
  /**
   * Position public description
   */
  public_description?: string | null;
  /**
   * Position public title
   */
  public_title?: string | null;
  /**
   * Position title
   */
  title?: string;
}
