/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Image {
  /**
   * Image ID
   */
  id?: string;
  /**
   * URL of image
   */
  url?: string;
}
