<ion-content class="ion-padding">
  <div class="card-holder">
    <ion-card>
      <ion-card-header>
        <ion-card-title class="ion-text-center">Select POS mode</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-list [inset]="true" mode="ios">
          <ion-item [button]="true" [routerLink]="'/auto-choice'">
            <ion-icon
              slot="start"
              name="qr-code-outline"
              size="large"
            ></ion-icon>
            <ion-label slot="end">Scan mode</ion-label>
          </ion-item>

          <ion-item disabled>
            <ion-icon slot="start" name="cash-outline" size="large"></ion-icon>
            <ion-label slot="end">POS mode</ion-label>
          </ion-item>

          <ion-item disabled>
            <ion-icon
              slot="start"
              name="rocket-outline"
              size="large"
            ></ion-icon>
            <ion-label slot="end">Fuel mode</ion-label>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
