/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { Coupon } from '../model/coupon';
// @ts-ignore
import { CouponAddedResult } from '../model/couponAddedResult';
// @ts-ignore
import { CouponCode } from '../model/couponCode';
// @ts-ignore
import { IncustControllersTermApiTerminalCouponByCodeRequest } from '../model/incustControllersTermApiTerminalCouponByCodeRequest';
// @ts-ignore
import { MessageResponse } from '../model/messageResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {
  protected basePath = '/v1/term';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substring(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Return list coupon object
   * @param data Array of coupon object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCouponRedeemWoUser(
    data: Array<Coupon>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<Coupon>>;
  public incustControllersTermApiCouponRedeemWoUser(
    data: Array<Coupon>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<Coupon>>>;
  public incustControllersTermApiCouponRedeemWoUser(
    data: Array<Coupon>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<Coupon>>>;
  public incustControllersTermApiCouponRedeemWoUser(
    data: Array<Coupon>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiCouponRedeemWoUser.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/redeem`;
    return this.httpClient.request<Array<Coupon>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return list of added coupon object
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param data Array of coupon codes object
   * @param userIdType Type of user identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCustomerCouponAdd(
    userIdValue: string,
    data: Array<CouponCode>,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<CouponAddedResult>;
  public incustControllersTermApiCustomerCouponAdd(
    userIdValue: string,
    data: Array<CouponCode>,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<CouponAddedResult>>;
  public incustControllersTermApiCustomerCouponAdd(
    userIdValue: string,
    data: Array<CouponCode>,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<CouponAddedResult>>;
  public incustControllersTermApiCustomerCouponAdd(
    userIdValue: string,
    data: Array<CouponCode>,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiCustomerCouponAdd.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiCustomerCouponAdd.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/user/add`;
    return this.httpClient.request<CouponAddedResult>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return list of added coupon object
   * @param userIdValue User phone number
   * @param userIdType Type of user identity
   * @param data Array of coupon codes object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCustomerCouponAddEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: Array<CouponCode>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<CouponAddedResult>;
  public incustControllersTermApiCustomerCouponAddEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: Array<CouponCode>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<CouponAddedResult>>;
  public incustControllersTermApiCustomerCouponAddEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: Array<CouponCode>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<CouponAddedResult>>;
  public incustControllersTermApiCustomerCouponAddEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: Array<CouponCode>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiCustomerCouponAddEx.'
      );
    }
    if (userIdType === null || userIdType === undefined) {
      throw new Error(
        'Required parameter userIdType was null or undefined when calling incustControllersTermApiCustomerCouponAddEx.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiCustomerCouponAddEx.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/${this.configuration.encodeParam({
      name: 'userIdType',
      value: userIdType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'phone' | 'qr' | 'email' | 'card' | 'temporary-card' | 'id' | 'external-id' | 'social-network' | 'ssn' | 'itin' | 'itn'",
      dataFormat: undefined
    })}/add`;
    return this.httpClient.request<CouponAddedResult>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return list of added coupon object
   * @param userIdValue User phone number
   * @param data Array of coupon codes object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCustomerCouponAddSimple(
    userIdValue: string,
    data: Array<CouponCode>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<CouponAddedResult>;
  public incustControllersTermApiCustomerCouponAddSimple(
    userIdValue: string,
    data: Array<CouponCode>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<CouponAddedResult>>;
  public incustControllersTermApiCustomerCouponAddSimple(
    userIdValue: string,
    data: Array<CouponCode>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<CouponAddedResult>>;
  public incustControllersTermApiCustomerCouponAddSimple(
    userIdValue: string,
    data: Array<CouponCode>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiCustomerCouponAddSimple.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiCustomerCouponAddSimple.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/add`;
    return this.httpClient.request<CouponAddedResult>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return list coupon object
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param data Array of coupon object
   * @param userIdType Type of user identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCustomerCouponRedeem(
    userIdValue: string,
    data: Array<Coupon>,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<Coupon>>;
  public incustControllersTermApiCustomerCouponRedeem(
    userIdValue: string,
    data: Array<Coupon>,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponRedeem(
    userIdValue: string,
    data: Array<Coupon>,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponRedeem(
    userIdValue: string,
    data: Array<Coupon>,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiCustomerCouponRedeem.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiCustomerCouponRedeem.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/user/redeem`;
    return this.httpClient.request<Array<Coupon>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return list coupon object
   * @param userIdValue User phone number
   * @param userIdType Type of user identity
   * @param data Array of coupon object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCustomerCouponRedeemEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: Array<Coupon>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<Coupon>>;
  public incustControllersTermApiCustomerCouponRedeemEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: Array<Coupon>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponRedeemEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: Array<Coupon>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponRedeemEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    data: Array<Coupon>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiCustomerCouponRedeemEx.'
      );
    }
    if (userIdType === null || userIdType === undefined) {
      throw new Error(
        'Required parameter userIdType was null or undefined when calling incustControllersTermApiCustomerCouponRedeemEx.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiCustomerCouponRedeemEx.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/${this.configuration.encodeParam({
      name: 'userIdType',
      value: userIdType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'phone' | 'qr' | 'email' | 'card' | 'temporary-card' | 'id' | 'external-id' | 'social-network' | 'ssn' | 'itin' | 'itn'",
      dataFormat: undefined
    })}/redeem`;
    return this.httpClient.request<Array<Coupon>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return list coupon object
   * @param userIdValue User phone number
   * @param data Array of coupon object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCustomerCouponRedeemSimple(
    userIdValue: string,
    data: Array<Coupon>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<Coupon>>;
  public incustControllersTermApiCustomerCouponRedeemSimple(
    userIdValue: string,
    data: Array<Coupon>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponRedeemSimple(
    userIdValue: string,
    data: Array<Coupon>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponRedeemSimple(
    userIdValue: string,
    data: Array<Coupon>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiCustomerCouponRedeemSimple.'
      );
    }
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiCustomerCouponRedeemSimple.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/redeem`;
    return this.httpClient.request<Array<Coupon>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return list of customers coupons
   * @param userIdValue User identifier, exclude search by user_phone, user_email and user_external_id fields (search by equals)
   * @param userIdType Type of user identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCustomerCoupons(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<Coupon>>;
  public incustControllersTermApiCustomerCoupons(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<Coupon>>>;
  public incustControllersTermApiCustomerCoupons(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<Coupon>>>;
  public incustControllersTermApiCustomerCoupons(
    userIdValue: string,
    userIdType?:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiCustomerCoupons.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (userIdValue !== undefined && userIdValue !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdValue,
        'user_id_value'
      );
    }
    if (userIdType !== undefined && userIdType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>userIdType,
        'user_id_type'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/user`;
    return this.httpClient.request<Array<Coupon>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return list of customers coupons
   * @param userIdValue User phone number
   * @param userIdType Type of user identity
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCustomerCouponsEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<Coupon>>;
  public incustControllersTermApiCustomerCouponsEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponsEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponsEx(
    userIdValue: string,
    userIdType:
      | 'phone'
      | 'qr'
      | 'email'
      | 'card'
      | 'temporary-card'
      | 'id'
      | 'external-id'
      | 'social-network'
      | 'ssn'
      | 'itin'
      | 'itn',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiCustomerCouponsEx.'
      );
    }
    if (userIdType === null || userIdType === undefined) {
      throw new Error(
        'Required parameter userIdType was null or undefined when calling incustControllersTermApiCustomerCouponsEx.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/${this.configuration.encodeParam({
      name: 'userIdType',
      value: userIdType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'phone' | 'qr' | 'email' | 'card' | 'temporary-card' | 'id' | 'external-id' | 'social-network' | 'ssn' | 'itin' | 'itn'",
      dataFormat: undefined
    })}`;
    return this.httpClient.request<Array<Coupon>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return list of customers coupons
   * @param userIdValue User phone number
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiCustomerCouponsSimple(
    userIdValue: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<Coupon>>;
  public incustControllersTermApiCustomerCouponsSimple(
    userIdValue: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponsSimple(
    userIdValue: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<Coupon>>>;
  public incustControllersTermApiCustomerCouponsSimple(
    userIdValue: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (userIdValue === null || userIdValue === undefined) {
      throw new Error(
        'Required parameter userIdValue was null or undefined when calling incustControllersTermApiCustomerCouponsSimple.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/${this.configuration.encodeParam({
      name: 'userIdValue',
      value: userIdValue,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}`;
    return this.httpClient.request<Array<Coupon>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return coupon object found by code
   * @param data Coupon codes object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTerminalCouponByCode(
    data: IncustControllersTermApiTerminalCouponByCodeRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<Coupon>>;
  public incustControllersTermApiTerminalCouponByCode(
    data: IncustControllersTermApiTerminalCouponByCodeRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<Coupon>>>;
  public incustControllersTermApiTerminalCouponByCode(
    data: IncustControllersTermApiTerminalCouponByCodeRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<Coupon>>>;
  public incustControllersTermApiTerminalCouponByCode(
    data: IncustControllersTermApiTerminalCouponByCodeRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (data === null || data === undefined) {
      throw new Error(
        'Required parameter data was null or undefined when calling incustControllersTermApiTerminalCouponByCode.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/coupon/get`;
    return this.httpClient.request<Array<Coupon>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: data,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Coupon emission and return emitted coupons list
   * @param code Coupon batch CODE.
   * @param count Count of coupons for emission
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public incustControllersTermApiTerminalCouponEmission(
    code: string,
    count?: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<Coupon>>;
  public incustControllersTermApiTerminalCouponEmission(
    code: string,
    count?: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<Coupon>>>;
  public incustControllersTermApiTerminalCouponEmission(
    code: string,
    count?: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<Coupon>>>;
  public incustControllersTermApiTerminalCouponEmission(
    code: string,
    count?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling incustControllersTermApiTerminalCouponEmission.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (count !== undefined && count !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>count,
        'count'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/coupons/batch/${this.configuration.encodeParam({
      name: 'code',
      value: code,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined
    })}/emission`;
    return this.httpClient.request<Array<Coupon>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }
}
