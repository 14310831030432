/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type LoyaltyRuleDependsType =
  | 'none'
  | 'amount'
  | 'quantity'
  | 'bonuses-earned'
  | 'bonuses-on-account';

export const LoyaltyRuleDependsType = {
  None: 'none' as LoyaltyRuleDependsType,
  Amount: 'amount' as LoyaltyRuleDependsType,
  Quantity: 'quantity' as LoyaltyRuleDependsType,
  BonusesEarned: 'bonuses-earned' as LoyaltyRuleDependsType,
  BonusesOnAccount: 'bonuses-on-account' as LoyaltyRuleDependsType
};
