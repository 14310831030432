/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TerminalExternalPaxDevice {
  /**
   * Device connection type
   */
  connection_type?: TerminalExternalPaxDevice.ConnectionTypeEnum;
  /**
   * Device settings (depends on device type)
   */
  settings?: object;
}
export namespace TerminalExternalPaxDevice {
  export type ConnectionTypeEnum = 'local';
  export const ConnectionTypeEnum = {
    Local: 'local' as ConnectionTypeEnum
  };
}
