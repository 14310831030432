{
  "name": "term",
  "version": "0.0.1",
  "scripts": {
    "android:run": "npx capacitor run android -l --external"
  },
  "dependencies": {
    "@incust/auth": "../../dist/libs/auth",
    "@incust/api-term": "../../dist/libs/api-term"
  }
}
