/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CustomerQuestionnaireRequestCustomer {
  /**
   * Customer user birth date
   */
  user_birth_date?: string | null;
  /**
   * Customer user gender
   */
  user_gender?: CustomerQuestionnaireRequestCustomer.UserGenderEnum | null;
  /**
   * Customer user Public Name
   */
  user_name?: string | null;
}
export namespace CustomerQuestionnaireRequestCustomer {
  export type UserGenderEnum = 'male' | 'female' | 'other';
  export const UserGenderEnum = {
    Male: 'male' as UserGenderEnum,
    Female: 'female' as UserGenderEnum,
    Other: 'other' as UserGenderEnum
  };
}
