/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FuelPumpJobsRec } from './fuelPumpJobsRec';
import { FuelNozzleRec } from './fuelNozzleRec';

export interface FuelPumpRec {
  /**
   * Is available available for operations (optional)
   */
  available?: boolean | null;
  /**
   * Pump ID
   */
  id?: string | null;
  /**
   * Internal pump ID / Pump number
   */
  internal_id?: string | null;
  jobs?: FuelPumpJobsRec;
  /**
   * Nozzles info (optional)
   */
  nozzles?: Array<FuelNozzleRec> | null;
  /**
   * Pump status (optional)
   */
  status?: FuelPumpRec.StatusEnum | null;
  /**
   * Pump status description (optional)
   */
  status_description?: string | null;
  /**
   * Linked terminal ID
   */
  terminal_id?: string | null;
}
export namespace FuelPumpRec {
  export type StatusEnum =
    | 'idle'
    | 'filling'
    | 'end-of-transaction'
    | 'offline';
  export const StatusEnum = {
    Idle: 'idle' as StatusEnum,
    Filling: 'filling' as StatusEnum,
    EndOfTransaction: 'end-of-transaction' as StatusEnum,
    Offline: 'offline' as StatusEnum
  };
}
