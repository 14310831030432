import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withPreloading
} from '@angular/router';
import { routes } from './app.routes';
import { IonicRouteStrategy } from '@ionic/angular';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from '@incust/auth';
import { environment } from '../environments/environment';
import * as packageInfo from '../../package.json';
import { ApiTermModule } from '@incust/api-term';

const version: string = (packageInfo as any).version;
const name: string = (packageInfo as any).name;

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({ mode: 'md' }),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    importProvidersFrom(
      HttpClientModule,
      ApiTermModule.forRoot(environment.apiUrlTest),
      AuthModule.forRoot({
        applicationPackageName: name || 'term',
        applicationType: 'web',
        applicationVersion: version || '1.0.0',
        language: environment.i18nDefaultLanguage || 'en'
      })
    )
  ]
};
