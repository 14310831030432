import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthHolderService } from '../services/auth-holder.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthHolderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    const options = this.auth.getOptions();

    console.log('Token:', token, 'Options:', options);

    // Инициализируем новые заголовки на основе исходных
    let headers = req.headers;

    // Добавляем заголовки из options
    if (options) {
      if (options.language) {
        headers = headers.set('Accept-Language', options.language);
      }

      if (options.applicationType) {
        headers = headers.set('X-Application-Type', options.applicationType);
      }

      if (options.applicationVersion) {
        headers = headers.set(
          'X-Application-Version',
          options.applicationVersion
        );
      }

      if (options.applicationPackageName) {
        headers = headers.set(
          'X-Application-Package-Name',
          options.applicationPackageName
        );
      }

      if (options.applicationLocalAddr) {
        headers = headers.set(
          'X-Application-Local-Addr',
          options.applicationLocalAddr
        );
      }
    }

    // Добавляем токен, если он есть
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    // Клонируем запрос с обновленными заголовками
    const clonedReq = req.clone({ headers });

    console.log('Cloned Request Headers:', clonedReq.headers);

    // Передаем запрос дальше по цепочке интерцепторов
    return next.handle(clonedReq);
  }
}
