/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FuelControllerConfiguration } from './fuelControllerConfiguration';
import { FuelPumpRec } from './fuelPumpRec';

export interface FuelRec {
  configuration?: FuelControllerConfiguration;
  /**
   * Configuration reading status (read only)
   */
  configuration_reading_status?: FuelRec.ConfigurationReadingStatusEnum | null;
  /**
   * Controller configuration last updated time
   */
  configuration_updated?: string | null;
  /**
   * Controller ID
   */
  id?: string | null;
  /**
   * Pump authorize confirmation type
   */
  pump_authorize_confirmation?: FuelRec.PumpAuthorizeConfirmationEnum | null;
  /**
   * Pump authorize type
   */
  pump_authorize_type?: FuelRec.PumpAuthorizeTypeEnum | null;
  /**
   * Pumps info
   */
  pumps?: Array<FuelPumpRec> | null;
  /**
   * Skip pumps when selecting fuel for filling
   */
  skip_pumps_in_selection?: number | null;
}
export namespace FuelRec {
  export type ConfigurationReadingStatusEnum =
    | 'connection-not-configured'
    | 'ready-to-read'
    | 'success'
    | 'fail';
  export const ConfigurationReadingStatusEnum = {
    ConnectionNotConfigured:
      'connection-not-configured' as ConfigurationReadingStatusEnum,
    ReadyToRead: 'ready-to-read' as ConfigurationReadingStatusEnum,
    Success: 'success' as ConfigurationReadingStatusEnum,
    Fail: 'fail' as ConfigurationReadingStatusEnum
  };
  export type PumpAuthorizeConfirmationEnum =
    | 'selected-nozzle-up'
    | 'without-nozzle-confirmation';
  export const PumpAuthorizeConfirmationEnum = {
    SelectedNozzleUp: 'selected-nozzle-up' as PumpAuthorizeConfirmationEnum,
    WithoutNozzleConfirmation:
      'without-nozzle-confirmation' as PumpAuthorizeConfirmationEnum
  };
  export type PumpAuthorizeTypeEnum = 'amount' | 'volume';
  export const PumpAuthorizeTypeEnum = {
    Amount: 'amount' as PumpAuthorizeTypeEnum,
    Volume: 'volume' as PumpAuthorizeTypeEnum
  };
}
