import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthHolderService } from '@incust/auth';

@Injectable({
  providedIn: 'root'
})
export class ModeGuard implements CanActivate {
  constructor(
    private authHolderService: AuthHolderService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const settings = this.authHolderService.getSettings();

    let routeRedirect = '';

    if (settings) {
      const { type } = settings;

      if (
        type === 'steward' ||
        type === 'validator' ||
        type === 'prepaid-services'
      ) {
        routeRedirect = 'steward';
      }

      if (type === 'register' || type === 'kiosk-and-register') {
        routeRedirect = 'pos-mode';
      }

      if (type === 'road-tanker-driver' || type === 'kiosk') {
        routeRedirect = 'transactions';
      }
    }

    if (!routeRedirect) {
      this.authHolderService.logout();
      this.router.navigate(['auth']);
      return false;
    }

    console.log('route', state.url);
    console.log('routeRedirect', routeRedirect);

    if (!state.url.includes(routeRedirect)) {
      console.log(9999);
      this.router.navigate([routeRedirect]);
    }

    return true;
  }
}
