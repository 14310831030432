import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiModule, Configuration } from './api';

@NgModule({
  imports: [ApiModule.forRoot(() => new Configuration({ basePath: '' }))],
  providers: []
})
export class ApiTermModule {
  static forRoot(basePath: string): ModuleWithProviders<ApiTermModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: Configuration,
          useFactory: () => new Configuration({ basePath: basePath })
        }
      ]
    };
  }
}
