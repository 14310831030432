/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CorporateSpecialAccountAccessPrice } from './corporateSpecialAccountAccessPrice';
import { PriceTaxes } from './priceTaxes';
import { PriceMargins } from './priceMargins';
import { CorporateSpecialAccountPrice } from './corporateSpecialAccountPrice';
import { CustomerPrice } from './customerPrice';
import { SpecialAccount } from './specialAccount';

export interface GoodsPrice {
  /**
   * Is price applicable for customer/corporate customer (readonly)
   */
  applicable?: boolean | null;
  /**
   * Price is applied only to identified customers, not to anonymous one
   */
  applicable_identified_customers_only?: boolean | null;
  corporate_special_account?: CorporateSpecialAccountPrice;
  /**
   * Prices for corporate special account access (DEPRECATED, replaced by customer_prices)
   */
  corporate_special_accounts_access?: Array<CorporateSpecialAccountAccessPrice> | null;
  /**
   * Currensy ISO code
   */
  currency?: string | null;
  /**
   * Prices available for customer
   */
  customer_prices?: Array<CustomerPrice> | null;
  /**
   * Price ID
   */
  id?: string | null;
  margins?: PriceMargins;
  /**
   * Price value
   */
  price?: number | null;
  /**
   * Number of decimal digits in price value
   */
  price_decimal_digits?: number | null;
  /**
   * Price including taxes
   */
  price_including_taxes?: number | null;
  /**
   * Posted (station level) price
   */
  price_posted?: number | null;
  /**
   * Sale (check, receipt) price (optional)
   */
  price_sale?: number | null;
  special_account?: SpecialAccount;
  /**
   * Special account ID
   */
  special_account_id?: string | null;
  taxes?: PriceTaxes;
  /**
   * Type of price origin
   */
  type?: GoodsPrice.TypeEnum;
}
export namespace GoodsPrice {
  export type TypeEnum = 'currency' | 'special-account';
  export const TypeEnum = {
    Currency: 'currency' as TypeEnum,
    SpecialAccount: 'special-account' as TypeEnum
  };
}
