/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Loyalty settings
 */
export interface LoyaltySettings {
  /**
   * Limit bonus points redeem in percentage of check amount
   */
  bonus_payment_limit?: number;
  /**
   * Bonus points payment settings source (optional)
   */
  bonus_payment_settings_source?: LoyaltySettings.BonusPaymentSettingsSourceEnum | null;
  /**
   * Bonus points redeem type (only full check amount, partial check amount, partial check amount rounded to the nearest whole number)
   */
  bonus_payment_type?: LoyaltySettings.BonusPaymentTypeEnum;
  /**
   * Automatic show customers questionaries in terminal
   */
  show_customers_questionnaire?: LoyaltySettings.ShowCustomersQuestionnaireEnum;
  /**
   * Limit bonus points redeem amount without PIN confirmation
   */
  unconfirmed_bonus_payment_amount?: number;
  /**
   * Limit discount amount without PIN confirmation
   */
  unconfirmed_discount_amount?: number;
}
export namespace LoyaltySettings {
  export type BonusPaymentSettingsSourceEnum = 'loyalty' | 'card-category';
  export const BonusPaymentSettingsSourceEnum = {
    Loyalty: 'loyalty' as BonusPaymentSettingsSourceEnum,
    CardCategory: 'card-category' as BonusPaymentSettingsSourceEnum
  };
  export type BonusPaymentTypeEnum =
    | 'full-check'
    | 'partial'
    | 'partial-rounded';
  export const BonusPaymentTypeEnum = {
    FullCheck: 'full-check' as BonusPaymentTypeEnum,
    Partial: 'partial' as BonusPaymentTypeEnum,
    PartialRounded: 'partial-rounded' as BonusPaymentTypeEnum
  };
  export type ShowCustomersQuestionnaireEnum =
    | 'do-not-show'
    | 'show-only-for-new'
    | 'show-for-all';
  export const ShowCustomersQuestionnaireEnum = {
    DoNotShow: 'do-not-show' as ShowCustomersQuestionnaireEnum,
    ShowOnlyForNew: 'show-only-for-new' as ShowCustomersQuestionnaireEnum,
    ShowForAll: 'show-for-all' as ShowCustomersQuestionnaireEnum
  };
}
