/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerPaymentSystemType } from './customerPaymentSystemType';
import { ExternalBankCardsEDCTypes } from './externalBankCardsEDCTypes';

export interface BankCardExternalInfo {
  edc_card_type?: ExternalBankCardsEDCTypes;
  /**
   * Payment Id
   */
  id?: string | null;
  /**
   * Payment status
   */
  status?: BankCardExternalInfo.StatusEnum | null;
  type?: CustomerPaymentSystemType;
}
export namespace BankCardExternalInfo {
  export type StatusEnum =
    | 'inprocess'
    | 'success'
    | 'fail'
    | 'canceled'
    | 'error'
    | 'authorized';
  export const StatusEnum = {
    Inprocess: 'inprocess' as StatusEnum,
    Success: 'success' as StatusEnum,
    Fail: 'fail' as StatusEnum,
    Canceled: 'canceled' as StatusEnum,
    Error: 'error' as StatusEnum,
    Authorized: 'authorized' as StatusEnum
  };
}
