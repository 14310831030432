/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type LoyaltyRuleDependsCalcPeriod =
  | 'all'
  | 'check'
  | 'day'
  | 'week'
  | 'month'
  | 'three-month'
  | 'year'
  | 'current-week'
  | 'past-week'
  | 'current-month'
  | 'past-month'
  | 'current-quarter'
  | 'past-quarter'
  | 'current-year'
  | 'past-year';

export const LoyaltyRuleDependsCalcPeriod = {
  All: 'all' as LoyaltyRuleDependsCalcPeriod,
  Check: 'check' as LoyaltyRuleDependsCalcPeriod,
  Day: 'day' as LoyaltyRuleDependsCalcPeriod,
  Week: 'week' as LoyaltyRuleDependsCalcPeriod,
  Month: 'month' as LoyaltyRuleDependsCalcPeriod,
  ThreeMonth: 'three-month' as LoyaltyRuleDependsCalcPeriod,
  Year: 'year' as LoyaltyRuleDependsCalcPeriod,
  CurrentWeek: 'current-week' as LoyaltyRuleDependsCalcPeriod,
  PastWeek: 'past-week' as LoyaltyRuleDependsCalcPeriod,
  CurrentMonth: 'current-month' as LoyaltyRuleDependsCalcPeriod,
  PastMonth: 'past-month' as LoyaltyRuleDependsCalcPeriod,
  CurrentQuarter: 'current-quarter' as LoyaltyRuleDependsCalcPeriod,
  PastQuarter: 'past-quarter' as LoyaltyRuleDependsCalcPeriod,
  CurrentYear: 'current-year' as LoyaltyRuleDependsCalcPeriod,
  PastYear: 'past-year' as LoyaltyRuleDependsCalcPeriod
};
