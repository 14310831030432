/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PriceGroupSettings {
  /**
   * Price group ID (optional)
   */
  id?: string;
  /**
   * Price group items Master Price change allowed (optional)
   */
  items_master_price_change_allowed?: boolean;
  /**
   * Price group items Taxes change allowed (optional)
   */
  items_taxes_change_allowed?: boolean;
  /**
   * Price group used
   */
  used?: boolean;
}
