/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CouponBatchLink {
  /**
   * White Label application Code (optional)
   */
  app_code?: string | null;
  /**
   * White Label application Id (optional)
   */
  app_id?: string | null;
  /**
   * White Label application Title (optional)
   */
  app_title?: string | null;
  /**
   * Link path for QR
   */
  coupon_path_qr?: string | null;
  /**
   * Link path for share
   */
  coupon_path_share?: string | null;
  /**
   * Link type
   */
  type?: CouponBatchLink.TypeEnum | null;
}
export namespace CouponBatchLink {
  export type TypeEnum = 'base' | 'white-label-app';
  export const TypeEnum = {
    Base: 'base' as TypeEnum,
    WhiteLabelApp: 'white-label-app' as TypeEnum
  };
}
