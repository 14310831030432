/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FuelControllerConfigurationMeasurementUnits {
  /**
   * Volume measurement unit
   */
  volume?: FuelControllerConfigurationMeasurementUnits.VolumeEnum | null;
}
export namespace FuelControllerConfigurationMeasurementUnits {
  export type VolumeEnum = 'L' | 'G';
  export const VolumeEnum = {
    L: 'L' as VolumeEnum,
    G: 'G' as VolumeEnum
  };
}
