import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseAbstractPage } from '../base.abstract.page';
import { addIcons } from 'ionicons';
import { qrCodeOutline, cashOutline, rocketOutline } from 'ionicons/icons';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-pos-mode-page',
  templateUrl: 'pos-mode.page.html',
  styleUrls: ['pos-mode.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ]
})
export class PosModePage extends BaseAbstractPage implements OnInit {
  ngOnInit() {
    addIcons({ qrCodeOutline, cashOutline, rocketOutline });
  }
}
