/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountLimits } from './accountLimits';
import { PriceCorporateCustomer } from './priceCorporateCustomer';
import { PriceRetailCustomer } from './priceRetailCustomer';

export interface PriceCustomerSpecialAccount {
  /**
   * Active flag
   */
  active?: number | null;
  corporate_customer?: PriceCorporateCustomer;
  /**
   * Credit limit (only for \'credit\' accounts)
   */
  credit_limit?: number | null;
  /**
   * Credit type
   */
  credit_type?: PriceCustomerSpecialAccount.CreditTypeEnum | null;
  /**
   * Customer account id
   */
  id?: string | null;
  limits?: AccountLimits;
  retail_customer?: PriceRetailCustomer;
}
export namespace PriceCustomerSpecialAccount {
  export type CreditTypeEnum = 'debit' | 'credit' | 'credit-unlimited';
  export const CreditTypeEnum = {
    Debit: 'debit' as CreditTypeEnum,
    Credit: 'credit' as CreditTypeEnum,
    CreditUnlimited: 'credit-unlimited' as CreditTypeEnum
  };
}
