/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ConsentInfo {
  /**
   * Comment
   */
  comment?: string;
  /**
   * Confirmation type
   */
  confirmation?: ConsentInfo.ConfirmationEnum;
  /**
   * Consent registered date and time
   */
  processed?: string;
  /**
   * Request data
   */
  request?: object;
  /**
   * Registration type
   */
  type?: ConsentInfo.TypeEnum;
}
export namespace ConsentInfo {
  export type ConfirmationEnum = 'pin' | 'email' | 'interface' | 'business';
  export const ConfirmationEnum = {
    Pin: 'pin' as ConfirmationEnum,
    Email: 'email' as ConfirmationEnum,
    Interface: 'interface' as ConfirmationEnum,
    Business: 'business' as ConfirmationEnum
  };
  export type TypeEnum =
    | 'business-registration'
    | 'customer-registration'
    | 'business-customer-import'
    | 'terminal-customer-registration';
  export const TypeEnum = {
    BusinessRegistration: 'business-registration' as TypeEnum,
    CustomerRegistration: 'customer-registration' as TypeEnum,
    BusinessCustomerImport: 'business-customer-import' as TypeEnum,
    TerminalCustomerRegistration: 'terminal-customer-registration' as TypeEnum
  };
}
