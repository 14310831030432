/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type MobileAppPaymentSystemType =
  | 'ipay'
  | 'stripe'
  | '4bill'
  | 'eway'
  | 'flutterwave'
  | 'global-payments'
  | 'wave';

export const MobileAppPaymentSystemType = {
  Ipay: 'ipay' as MobileAppPaymentSystemType,
  Stripe: 'stripe' as MobileAppPaymentSystemType,
  _4bill: '4bill' as MobileAppPaymentSystemType,
  Eway: 'eway' as MobileAppPaymentSystemType,
  Flutterwave: 'flutterwave' as MobileAppPaymentSystemType,
  GlobalPayments: 'global-payments' as MobileAppPaymentSystemType,
  Wave: 'wave' as MobileAppPaymentSystemType
};
