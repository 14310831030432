/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type CustomerAccessType =
  | 'full-bonuses-access'
  | 'add-bonuses-only'
  | 'waiting-for-confirmation'
  | 'in-black-list';

export const CustomerAccessType = {
  FullBonusesAccess: 'full-bonuses-access' as CustomerAccessType,
  AddBonusesOnly: 'add-bonuses-only' as CustomerAccessType,
  WaitingForConfirmation: 'waiting-for-confirmation' as CustomerAccessType,
  InBlackList: 'in-black-list' as CustomerAccessType
};
