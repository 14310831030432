/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdditionalServiceCost } from './additionalServiceCost';

export interface AdditionalService {
  /**
   * Service code
   */
  code?: string | null;
  /**
   * Service cost
   */
  cost?: Array<AdditionalServiceCost> | null;
  /**
   * Service is default
   */
  default?: number | null;
  /**
   * Additional service ID
   */
  id?: string | null;
  /**
   * Service description
   */
  public_description?: string | null;
  /**
   * Service title
   */
  public_title?: string | null;
  /**
   * Sort order weight
   */
  sort_order?: number;
  /**
   * Service type
   */
  type?: AdditionalService.TypeEnum | null;
}
export namespace AdditionalService {
  export type TypeEnum = 'delivery' | 'commission';
  export const TypeEnum = {
    Delivery: 'delivery' as TypeEnum,
    Commission: 'commission' as TypeEnum
  };
}
