/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LoyaltyLinks } from './loyaltyLinks';
import { LoyaltyCategory } from './loyaltyCategory';
import { AdditionalService } from './additionalService';
import { PhoneNumber } from './phoneNumber';
import { Image } from './image';

/**
 * Point of sale
 */
export interface Pos {
  /**
   * Additional services (optional)
   */
  additional_services?: Array<AdditionalService> | null;
  /**
   * Is additional services required
   */
  additional_services_required?: number;
  /**
   * Point of sales address
   */
  addresses?: string;
  /**
   * Categories
   */
  categories?: Array<LoyaltyCategory>;
  /**
   * Pos check items entering mode possibilities
   */
  check_item_enter_mode?: Pos.CheckItemEnterModeEnum | null;
  /**
   * Point of sales country code
   */
  country?: string;
  /**
   * Point of sales ID
   */
  id?: string;
  links?: LoyaltyLinks;
  /**
   * Maximum service distance in km (0 - unlimited)
   */
  maximum_service_distance?: number;
  /**
   * Phone prefix in international format. Sample \'+380\'
   */
  phone_prefix?: string;
  /**
   * Local interpretation of phone prefix in international format. Sample \'+380\'
   */
  phone_prefix_local?: string;
  /**
   * Phones of sale point
   */
  phones?: Array<PhoneNumber>;
  photos?: Array<Image>;
  /**
   * Point of sales title
   */
  title?: string;
  /**
   * Pos type
   */
  type?: Pos.TypeEnum;
}
export namespace Pos {
  export type CheckItemEnterModeEnum =
    | 'without-limits'
    | 'presets-only'
    | 'manually-only';
  export const CheckItemEnterModeEnum = {
    WithoutLimits: 'without-limits' as CheckItemEnterModeEnum,
    PresetsOnly: 'presets-only' as CheckItemEnterModeEnum,
    ManuallyOnly: 'manually-only' as CheckItemEnterModeEnum
  };
  export type TypeEnum = 'pos' | 'road-tanker' | 'online-store';
  export const TypeEnum = {
    Pos: 'pos' as TypeEnum,
    RoadTanker: 'road-tanker' as TypeEnum,
    OnlineStore: 'online-store' as TypeEnum
  };
}
