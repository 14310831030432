import { Component, inject, OnInit } from '@angular/core';
import { AuthHolderService } from '@incust/auth';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  private authService = inject(AuthHolderService);
  private router = inject(Router);

  ngOnInit() {
    // if (this.authService.isAuthenticated()) {
    //   this.router.navigateByUrl('/pos-mode', { replaceUrl: true });
    // }
  }
}
