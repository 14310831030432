/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RouteOperationsCount {
  assistance?: number | null;
  canceled?: number | null;
  failed?: number | null;
  pending?: number | null;
  processed?: number | null;
  refueling?: number | null;
  shipping?: number | null;
}
