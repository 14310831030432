/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FuelControllerConfiguration } from './fuelControllerConfiguration';
import { FuelControllerGpsData } from './fuelControllerGpsData';
import { FuelPumpRec } from './fuelPumpRec';
import { FuelGradeRec } from './fuelGradeRec';
import { FuelTank } from './fuelTank';
import { FuelNozzleRec } from './fuelNozzleRec';

export interface FuelController {
  actual_gps_data?: FuelControllerGpsData;
  configuration?: FuelControllerConfiguration;
  /**
   * Configuration reading status (read only)
   */
  configuration_reading_status?: FuelController.ConfigurationReadingStatusEnum | null;
  /**
   * Controller configuration last updated time
   */
  configuration_updated?: string | null;
  /**
   * Break Tank to Grade link if received zero volume product (only if used manual Tank to Grade link)
   */
  delete_tank_grade_link_by_zero?: number | null;
  /**
   * Grades info (only for detailed view, not for list)
   */
  grades?: Array<FuelGradeRec> | null;
  /**
   * Controller ID
   */
  id?: string | null;
  /**
   * Use manual Pump Nozzle to Grade link
   */
  manual_pump_nozzle_grade_link?: number | null;
  /**
   * Use manual Pump Nozzle to Tank link
   */
  manual_pump_nozzle_tank_link?: number | null;
  /**
   * Use manual Tank to Grade link
   */
  manual_tank_grade_link?: number | null;
  /**
   * Pump nozzles info (only for detailed view, not for list)
   */
  nozzles?: Array<FuelNozzleRec> | null;
  /**
   * Pump authorize type
   */
  pump_authorize_type?: FuelController.PumpAuthorizeTypeEnum | null;
  /**
   * Pumps info (only for detailed view, not for list)
   */
  pumps?: Array<FuelPumpRec> | null;
  /**
   * Skip pumps when selecting fuel for filling
   */
  skip_pumps_in_selection?: number | null;
  /**
   * Tanks info (only for detailed view, not for list)
   */
  tanks?: Array<FuelTank> | null;
}
export namespace FuelController {
  export type ConfigurationReadingStatusEnum =
    | 'connection-not-configured'
    | 'ready-to-read'
    | 'success'
    | 'fail';
  export const ConfigurationReadingStatusEnum = {
    ConnectionNotConfigured:
      'connection-not-configured' as ConfigurationReadingStatusEnum,
    ReadyToRead: 'ready-to-read' as ConfigurationReadingStatusEnum,
    Success: 'success' as ConfigurationReadingStatusEnum,
    Fail: 'fail' as ConfigurationReadingStatusEnum
  };
  export type PumpAuthorizeTypeEnum = 'amount' | 'volume';
  export const PumpAuthorizeTypeEnum = {
    Amount: 'amount' as PumpAuthorizeTypeEnum,
    Volume: 'volume' as PumpAuthorizeTypeEnum
  };
}
