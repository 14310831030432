/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TankConfiguration {
  /**
   * Critical high product height alarm, mm
   */
  critical_high_product_alarm_height?: number | null;
  /**
   * Critical low product height alarm, mm
   */
  critical_low_product_alarm_height?: number | null;
  /**
   * Height, mm
   */
  height?: number | null;
  /**
   * High product height alarm, mm
   */
  high_product_alarm_height?: number | null;
  /**
   * High water alarm height, mm
   */
  high_water_alarm_height?: number | null;
  /**
   * Low product height alarm, mm
   */
  low_product_alarm_height?: number | null;
}
