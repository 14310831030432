/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface LoyaltyCategory {
  /**
   * ID
   */
  id?: number;
  /**
   * Image URL
   */
  image_url?: string;
  /**
   * Title
   */
  title?: string;
}
