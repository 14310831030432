/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TerminalTokenRequest {
  /**
   * Terminal login
   */
  login: string;
  /**
   * Terminal password
   */
  password: string;
}
