/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface IncustControllersTermApiOnlineStoreChangeOrderStatusRequest {
  /**
   * Status
   */
  status: IncustControllersTermApiOnlineStoreChangeOrderStatusRequest.StatusEnum;
}
export namespace IncustControllersTermApiOnlineStoreChangeOrderStatusRequest {
  export type StatusEnum = 'canceled' | 'delivered' | 'inprocess';
  export const StatusEnum = {
    Canceled: 'canceled' as StatusEnum,
    Delivered: 'delivered' as StatusEnum,
    Inprocess: 'inprocess' as StatusEnum
  };
}
