/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BonusAdded {
  /**
   * Added bonuses amount
   */
  amount: number;
  /**
   * Added bonuses expire datetime
   */
  expire?: string | null;
  /**
   * Added bonuses starting datetime
   */
  starting?: string | null;
}
