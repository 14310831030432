/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionType } from './transactionType';
import { TransactionErrors } from './transactionErrors';

export interface Transaction {
  amount?: number;
  /**
   * Number of decimal digits in amount value
   */
  amount_decimal_digits?: number;
  amount_to_pay?: number;
  bonuses_added?: number;
  /**
   * Number of decimal digits in bonuses value
   */
  bonuses_decimal_digits?: number;
  bonuses_on_account?: number;
  bonuses_redeemed?: number;
  /**
   * Business Staff ID (optional)
   */
  business_staff_id?: string | null;
  /**
   * Business Staff name (optional)
   */
  business_staff_name?: string | null;
  /**
   * Is transaction cancelation allowed (optional, for authorize-payment transaction type only)
   */
  cancel_allowed?: boolean;
  /**
   * Comment
   */
  cancellation_comment?: string;
  cancelled?: number;
  /**
   * Comment
   */
  comment?: string;
  /**
   * Comment
   */
  confirmation_comment?: string;
  /**
   * Corporate customer email (optional)
   */
  corporate_customer_email?: string;
  /**
   * Corporate customer ID (optional)
   */
  corporate_customer_id?: string;
  /**
   * Corporate customer name (optional)
   */
  corporate_customer_name?: string;
  /**
   * Instance of corporate customer special account ID
   */
  corporate_customer_special_account_id?: string;
  /**
   * Currency ISO code (if transaction payment \'currency\')
   */
  currency?: string;
  /**
   * Instance of customer special account ID
   */
  customer_special_account_id?: string;
  discount_amount?: number;
  /**
   * Errors list (optional)
   */
  errors?: Array<TransactionErrors>;
  /**
   * Is transaction finalization allowed (optional, for authorize-payment transaction type only)
   */
  finalize_allowed?: boolean;
  /**
   * Transaction UTC finalized date-time
   */
  finalized?: string;
  /**
   * Transaction local (by POS time zone) finalized date-time
   */
  finalized_local?: string;
  /**
   * Transaction ID
   */
  id?: string;
  /**
   * Loyalty title (optional)
   */
  loyalty_title?: string;
  /**
   * Online store order ID (optional)
   */
  online_store_order_id?: string;
  /**
   * Payment type
   */
  payment_type?: Transaction.PaymentTypeEnum;
  /**
   * POS title (optional)
   */
  pos_title?: string;
  /**
   * Transaction UTC processed date-time
   */
  processed?: string;
  /**
   * Transaction local (by POS time zone) processed date-time
   */
  processed_local?: string;
  /**
   * Temporary code bonus points redeem allowed flag (optional)
   */
  sc_bonuses_redeem?: number;
  /**
   * Temporary code (optional)
   */
  sc_code?: string;
  sc_created?: string;
  /**
   * Temporary code terminal external Id (optional)
   */
  sc_terminal_external_id?: string;
  /**
   * Temporary code terminal ID (optional)
   */
  sc_terminal_id?: string;
  /**
   * Temporary code terminal login (optional)
   */
  sc_terminal_login?: string;
  /**
   * Temporary code terminal phone (optional)
   */
  sc_terminal_phone?: string;
  /**
   * Temporary code terminal title (optional)
   */
  sc_terminal_title?: string;
  services_amount?: number;
  shipping_amount?: number;
  special_account_amount?: number;
  /**
   * Special account currency ISO code
   */
  special_account_currency?: string;
  /**
   * Special account ID (if transaction payment in \'special-account\', \'corporate-special-account-access\', \'retail-special-account-access\')
   */
  special_account_id?: string;
  /**
   * Special account title
   */
  special_account_title?: string;
  /**
   * Special account type
   */
  special_account_type?: Transaction.SpecialAccountTypeEnum;
  /**
   * Number of decimal digits in special account value
   */
  special_accounts_decimal_digits?: number;
  subtype?: TransactionType;
  /**
   * Sum of amount and shipping_amount and services_amount
   */
  summary_amount?: number;
  /**
   * Terminal External Id (optional)
   */
  terminal_external_id?: string;
  /**
   * Terminal login (optional)
   */
  terminal_login?: string;
  /**
   * Terminal phone (optional)
   */
  terminal_phone?: string;
  /**
   * Terminal title (optional)
   */
  terminal_title?: string;
  /**
   * Referenced transaction ID
   */
  transaction_id?: string;
  type?: TransactionType;
  /**
   * User email (optional)
   */
  user_email?: string;
  /**
   * User external id (optional)
   */
  user_external_id?: string;
  /**
   * User ID (optional)
   */
  user_id?: string;
  /**
   * User name (optional)
   */
  user_name?: string;
  /**
   * User phone number (optional)
   */
  user_phone?: string;
}
export namespace Transaction {
  export type PaymentTypeEnum =
    | 'currency'
    | 'special-account'
    | 'corporate-special-account-access'
    | 'retail-special-account-access';
  export const PaymentTypeEnum = {
    Currency: 'currency' as PaymentTypeEnum,
    SpecialAccount: 'special-account' as PaymentTypeEnum,
    CorporateSpecialAccountAccess:
      'corporate-special-account-access' as PaymentTypeEnum,
    RetailSpecialAccountAccess:
      'retail-special-account-access' as PaymentTypeEnum
  };
  export type SpecialAccountTypeEnum = 'money' | 'goods' | 'punches';
  export const SpecialAccountTypeEnum = {
    Money: 'money' as SpecialAccountTypeEnum,
    Goods: 'goods' as SpecialAccountTypeEnum,
    Punches: 'punches' as SpecialAccountTypeEnum
  };
}
