import { Route } from '@angular/router';
import { AuthPage } from './pages/auth/auth.page';
import { PosModePage } from './pages/pos-mode/pos-mode.page';
import { AutoChoicePage } from './pages/auto-choice/auto-choice.page';

export const routes: Route[] = [
  {
    path: 'auth',
    component: AuthPage
  },
  {
    path: 'pos-mode',
    component: PosModePage
  },
  {
    path: 'auto-choice',
    component: AutoChoicePage
  }
];
