/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountLimits } from './accountLimits';
import { CorporateCustomerInfo } from './corporateCustomerInfo';
import { SpecialAccount } from './specialAccount';

/**
 * Corporate customer special account info
 */
export interface CorporateCustomerSpecialAccount {
  /**
   * Account active flag
   */
  active?: number;
  /**
   * Amount of funds on the account available for using (null if unlimited)
   */
  available_amount?: number;
  corporate_customer?: CorporateCustomerInfo;
  /**
   * Credit limit (for \'credit\' account type)
   */
  credit_limit?: number;
  /**
   * Credit type
   */
  credit_type?: CorporateCustomerSpecialAccount.CreditTypeEnum;
  /**
   * Account applicable goods filter
   */
  goods_items?: Array<string>;
  /**
   * Corporate customer special account ID
   */
  id?: string;
  limits?: AccountLimits;
  special_account?: SpecialAccount;
}
export namespace CorporateCustomerSpecialAccount {
  export type CreditTypeEnum = 'debit' | 'credit' | 'credit-unlimited';
  export const CreditTypeEnum = {
    Debit: 'debit' as CreditTypeEnum,
    Credit: 'credit' as CreditTypeEnum,
    CreditUnlimited: 'credit-unlimited' as CreditTypeEnum
  };
}
