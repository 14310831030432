/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TourCardAccount {
  /**
   * Account amount
   */
  amount?: number;
  /**
   * Account ID
   */
  id?: string;
  /**
   * Account precision
   */
  precision?: TourCardAccount.PrecisionEnum;
  /**
   * public title
   */
  public_title?: string;
}
export namespace TourCardAccount {
  export type PrecisionEnum =
    | 'integer'
    | 'two-decimal-places'
    | 'three-decimal-places';
  export const PrecisionEnum = {
    Integer: 'integer' as PrecisionEnum,
    TwoDecimalPlaces: 'two-decimal-places' as PrecisionEnum,
    ThreeDecimalPlaces: 'three-decimal-places' as PrecisionEnum
  };
}
