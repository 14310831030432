/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FuelControllerConfigurationDecimalDigits {
  /**
   * Number of decimal digits in amount value
   */
  amount?: number;
  /**
   * Number of decimal digits in price value
   */
  price?: number;
  /**
   * Number of decimal digits in volume value
   */
  volume?: number;
}
