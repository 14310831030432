/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CouponBatchAlias {
  /**
   * Alias active flag
   */
  active?: number | null;
  /**
   * Alias CODE
   */
  code?: string | null;
  /**
   * User ID (optional)
   */
  user_id?: string | null;
  /**
   * User Name (optional)
   */
  user_name?: string | null;
}
