/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface LoyaltyLinks {
  facebook?: string;
  google?: string;
  instagram?: string;
  linkedin?: string;
  pinterest?: string;
  twitter?: string;
  vk?: string;
  web?: string;
}
