/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SystemParams {
  /**
   * Platform allow customers list sort
   */
  allow_customers_list_sort?: boolean;
  /**
   * Platform allow send messages to external system by External ID
   */
  allow_messages_to_external_system?: boolean;
  /**
   * Platform default check amount decimal digits
   */
  default_check_amount_decimal_digits?: number;
  /**
   * Platform default check bonuses decimal digits
   */
  default_check_bonuses_decimal_digits?: number;
  /**
   * Platform default check special accounts decimal digits
   */
  default_check_special_accounts_decimal_digits?: number;
  /**
   * Platform default item amount decimal digits
   */
  default_item_amount_decimal_digits?: number;
  /**
   * Platform default item bonuses decimal digits
   */
  default_item_bonuses_decimal_digits?: number;
  /**
   * Platform default item price decimal digits
   */
  default_item_price_decimal_digits?: number;
  /**
   * Platform default item special accounts decimal digits
   */
  default_item_special_accounts_decimal_digits?: number;
  /**
   * Platform maximum check amount decimal digits
   */
  maximum_check_amount_decimal_digits?: number;
  /**
   * Platform maximum check bonuses decimal digits
   */
  maximum_check_bonuses_decimal_digits?: number;
  /**
   * Platform maximum check special accounts decimal digits
   */
  maximum_check_special_accounts_decimal_digits?: number;
  /**
   * Platform maximum item amount decimal digits
   */
  maximum_item_amount_decimal_digits?: number;
  /**
   * Platform maximum item bonuses decimal digits
   */
  maximum_item_bonuses_decimal_digits?: number;
  /**
   * Platform maximum item price decimal digits
   */
  maximum_item_price_decimal_digits?: number;
  /**
   * Platform maximum item special accounts decimal digits
   */
  maximum_item_special_accounts_decimal_digits?: number;
  /**
   * Privacy Policy URL
   */
  privacy_policy_url?: string;
  /**
   * External ID support
   */
  support_external_id_primary_identifier?: boolean;
  /**
   * Terms of Service URL
   */
  terms_of_service_url?: string;
}
