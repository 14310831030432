/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CustomerQuestionnaireResponseCustomer {
  /**
   * Customer user birth date
   */
  user_birth_date?: string | null;
  /**
   * Customer user birthdate changing allowed (readonly, optional)
   */
  user_birth_date_change_allowed?: boolean | null;
  /**
   * Customer user gender
   */
  user_gender?: CustomerQuestionnaireResponseCustomer.UserGenderEnum | null;
  /**
   * Customer user gender changing allowed (readonly, optional)
   */
  user_gender_change_allowed?: boolean | null;
  /**
   * Customer user Public Name
   */
  user_name?: string | null;
  /**
   * Customer user Public Name changing allowed (readonly, optional)
   */
  user_name_change_allowed?: boolean | null;
}
export namespace CustomerQuestionnaireResponseCustomer {
  export type UserGenderEnum = 'male' | 'female' | 'other';
  export const UserGenderEnum = {
    Male: 'male' as UserGenderEnum,
    Female: 'female' as UserGenderEnum,
    Other: 'other' as UserGenderEnum
  };
}
