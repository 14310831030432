/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserSignature } from './userSignature';

export interface UserExternalForm {
  /**
   * Form filled date (optional, for filled forms only, readonly)
   */
  created?: string;
  /**
   * External Form Id (readonly)
   */
  external_form_id?: string | null;
  /**
   * Empty form file URL
   */
  file?: string | null;
  /**
   * Form data object
   */
  form_data?: object;
  /**
   * User Form Id (optional, readonly)
   */
  id?: string | null;
  signature?: UserSignature;
  /**
   * Form signed by user (optional, for filled forms only, readonly)
   */
  signed?: boolean | null;
  /**
   * Form title
   */
  title?: string | null;
  /**
   * Form type
   */
  type?: UserExternalForm.TypeEnum | null;
  /**
   * User data object
   */
  user_data?: object;
  /**
   * User Id (optional, readonly)
   */
  user_id?: string | null;
}
export namespace UserExternalForm {
  export type TypeEnum = 'tax';
  export const TypeEnum = {
    Tax: 'tax' as TypeEnum
  };
}
