/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeedbackSource } from './feedbackSource';

export interface CustomerFeedbackSettings {
  /**
   * Feedback system active flag
   */
  active?: number;
  feedback_source?: FeedbackSource;
  /**
   * Print feedback source on check
   */
  print_feedback_on_check?: CustomerFeedbackSettings.PrintFeedbackOnCheckEnum;
  /**
   * Invitation message text for printinting on check
   */
  print_feedback_on_check_message_text?: string;
  /**
   * Send invitation to ratings/reviews in mobile app when finalioze transaction
   */
  transaction_feedback_message_send?: number;
}
export namespace CustomerFeedbackSettings {
  export type PrintFeedbackOnCheckEnum = 'none' | 'qr-code' | 'url' | 'all';
  export const PrintFeedbackOnCheckEnum = {
    None: 'none' as PrintFeedbackOnCheckEnum,
    QrCode: 'qr-code' as PrintFeedbackOnCheckEnum,
    Url: 'url' as PrintFeedbackOnCheckEnum,
    All: 'all' as PrintFeedbackOnCheckEnum
  };
}
