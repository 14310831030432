/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProbeAlarm } from './probeAlarm';

export interface ProbeMeasurements {
  /**
   * Alarms (if present)
   */
  alarms?: Array<ProbeAlarm> | null;
  /**
   * Controller ID
   */
  controller_id?: string | null;
  /**
   * Measurements time
   */
  processed?: string | null;
  /**
   * Value of product density in in 0.1 kg/m3 units
   */
  product_density?: number | null;
  /**
   * Value of product height in 0.1 mm
   */
  product_height?: number | null;
  /**
   * Value of product mass in 0.1 kg
   */
  product_mass?: number | null;
  /**
   * Value of product temperature compensated volume in liters
   */
  product_tc_volume?: number | null;
  /**
   * Value of product ullage in liters
   */
  product_ullage?: number | null;
  /**
   * Value of product volume in liters
   */
  product_volume?: number | null;
  /**
   * Status of probe and measurements
   */
  status?: ProbeMeasurements.StatusEnum | null;
  /**
   * Value of tank filling in percentage
   */
  tank_filling_percentage?: number | null;
  /**
   * Tank ID
   */
  tank_id?: string | null;
  /**
   * Value product temperature in 0.1 degrees Celcium
   */
  temperature?: number | null;
  /**
   * Value of water height in 0.1 mm
   */
  water_height?: number | null;
  /**
   * Value of water volume in liters
   */
  water_volume?: number | null;
}
export namespace ProbeMeasurements {
  export type StatusEnum = 'OK' | 'Error' | 'Offline';
  export const StatusEnum = {
    Ok: 'OK' as StatusEnum,
    Error: 'Error' as StatusEnum,
    Offline: 'Offline' as StatusEnum
  };
}
