/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface MobileAppPaymentSettingsFee {
  /**
   * Fixed amount of fee
   */
  fixed?: number;
  /**
   * Percentage from payment amount part of fee
   */
  percentage?: number;
}
