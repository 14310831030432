/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type CustomerPaymentSystemType =
  | 'ipay'
  | 'stripe'
  | 'amp'
  | '4bill'
  | 'eway'
  | 'flutterwave'
  | 'global-payments'
  | 'wave'
  | 'pax';

export const CustomerPaymentSystemType = {
  Ipay: 'ipay' as CustomerPaymentSystemType,
  Stripe: 'stripe' as CustomerPaymentSystemType,
  Amp: 'amp' as CustomerPaymentSystemType,
  _4bill: '4bill' as CustomerPaymentSystemType,
  Eway: 'eway' as CustomerPaymentSystemType,
  Flutterwave: 'flutterwave' as CustomerPaymentSystemType,
  GlobalPayments: 'global-payments' as CustomerPaymentSystemType,
  Wave: 'wave' as CustomerPaymentSystemType,
  Pax: 'pax' as CustomerPaymentSystemType
};
