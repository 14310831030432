/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CheckItemAdditionalInfo } from './checkItemAdditionalInfo';
import { GoodsPassThru } from './goodsPassThru';
import { CheckTaxes } from './checkTaxes';
import { PriceTaxes } from './priceTaxes';
import { PriceMargins } from './priceMargins';
import { CheckMargins } from './checkMargins';

export interface CheckItem {
  additions?: CheckItemAdditionalInfo;
  /**
   * Item row total
   */
  amount?: number | null;
  /**
   * Number of decimal digits in amount value
   */
  amount_decimal_digits?: number | null;
  /**
   * Item row total by authorized payment transaction
   */
  authorized_amount?: number | null;
  /**
   * Item quantity by authorized payment transaction
   */
  authorized_quantity?: number | null;
  /**
   * Amount of added bonuses
   */
  bonuses_added?: number | null;
  /**
   * Number of decimal digits in bonuses value
   */
  bonuses_decimal_digits?: number | null;
  /**
   * Goods item total bonus points redeemed calculated by check (for list with stock)
   */
  calculated_bonuses_redeemed_amount?: number | null;
  /**
   * Goods item total discount calculated by rules (for list with stock)
   */
  calculated_discount_amount?: number | null;
  calculated_margins?: CheckMargins;
  /**
   * Goods item price calculated by rules (for list with stock)
   */
  calculated_price?: number | null;
  calculated_taxes?: CheckTaxes;
  /**
   * Goods item unit bonus points redeemed calculated by check (for list with stock)
   */
  calculated_unit_bonuses_redeemed_amount?: number | null;
  /**
   * Goods item unit discount calculated by rules (for list with stock)
   */
  calculated_unit_discount_amount?: number | null;
  /**
   * item category
   */
  category?: string | null;
  /**
   * item code
   */
  code: string | null;
  /**
   * Amount of discount
   */
  discount_amount?: number | null;
  /**
   * Loyalty item unique id related to check item (optional, readonly)
   */
  loyalty_item_id?: string | null;
  margins?: PriceMargins;
  passthru_data?: GoodsPassThru;
  /**
   * Item price
   */
  price?: number | null;
  /**
   * Number of decimal digits in price value
   */
  price_decimal_digits?: number | null;
  /**
   * Item row total by job
   */
  processed_amount?: number | null;
  /**
   * Item quantity by job
   */
  processed_quantity?: number | null;
  /**
   * Item quantity
   */
  quantity?: number | null;
  /**
   * Number of decimal digits in quantity value (only for quantity_recalculate_type is applicable)
   */
  quantity_decimal_digits?: number | null;
  /**
   * Quantity recalculate type (if needed)
   */
  quantity_recalculate_type?: CheckItem.QuantityRecalculateTypeEnum | null;
  /**
   * Number of decimal digits in special account value
   */
  special_accounts_decimal_digits?: number | null;
  taxes?: PriceTaxes;
  /**
   * item title
   */
  title?: string | null;
  /**
   * Measuring units
   */
  unit?: string | null;
  /**
   * Weight goods flag
   */
  weighing?: number | null;
  /**
   * Item quantity only by whole number flag
   */
  whole_number?: number | null;
}
export namespace CheckItem {
  export type QuantityRecalculateTypeEnum =
    | 'adjust-by-amount'
    | 'recalculate-by-amount';
  export const QuantityRecalculateTypeEnum = {
    AdjustByAmount: 'adjust-by-amount' as QuantityRecalculateTypeEnum,
    RecalculateByAmount: 'recalculate-by-amount' as QuantityRecalculateTypeEnum
  };
}
