/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Corporate customer
 */
export interface PriceCorporateUser {
  /**
   * Corporate user Email
   */
  email?: string;
  /**
   * Corporate user name
   */
  name?: string;
  /**
   * User status (1 - active, 0 - suspended)
   */
  status?: number;
}
