/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CustomerField {
  /**
   * Some help for user
   */
  description: string;
  dictionary_values?: Array<string>;
  /**
   * Field id
   */
  id?: string;
  /**
   * Sort order weight
   */
  sort_order?: number;
  /**
   * Field type
   */
  type: CustomerField.TypeEnum;
  /**
   * Field Value
   */
  value?: string;
  /**
   * Prettified Field Value
   */
  value_prettified?: object;
}
export namespace CustomerField {
  export type TypeEnum = 'string' | 'integer' | 'money' | 'date' | 'dictionary';
  export const TypeEnum = {
    String: 'string' as TypeEnum,
    Integer: 'integer' as TypeEnum,
    Money: 'money' as TypeEnum,
    Date: 'date' as TypeEnum,
    Dictionary: 'dictionary' as TypeEnum
  };
}
