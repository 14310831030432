/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SpecialAccountCharge {
  /**
   * Charge amount
   */
  amount: number;
  /**
   * Currensy ISO code
   */
  currency?: string | null;
  /**
   * Special account id
   */
  id: string;
  /**
   * Account type
   */
  type?: SpecialAccountCharge.TypeEnum;
}
export namespace SpecialAccountCharge {
  export type TypeEnum = 'money' | 'goods' | 'punches';
  export const TypeEnum = {
    Money: 'money' as TypeEnum,
    Goods: 'goods' as TypeEnum,
    Punches: 'punches' as TypeEnum
  };
}
