/**
 * inCust terminal API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RuleCalculationBaseItemData {
  /**
   * Item amount
   */
  amount?: number | null;
  /**
   * Item total bonus points redeemed calculated by check
   */
  bonuses_redeemed_amount?: number | null;
  /**
   * Check item index
   */
  index?: number | null;
  /**
   * Item price
   */
  price?: number | null;
  /**
   * Item quantity
   */
  quantity?: number | null;
  /**
   * Item unit bonus points redeemed calculated by check
   */
  unit_bonuses_redeemed_amount?: number | null;
}
